import React from "react";
import styled from "styled-components"
import tw from "twin.macro";
import FormField from "../components/FormField";
import { encryptData, longInput } from "../const";
import ErrorMessage from "../components/ErrorMessage";
import MainButton from "../components/MainButton";
import ErrorPopUp from "../components/ErrorPopUp";
import CorrectPopUp from "../components/CorrectPopUp";
import { gql, useMutation } from "@apollo/client";
import lock from "../icons/sm_lock.png"
import back_arrow from "../icons/back_arrow.png"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Arrow = styled.div`
  width: 25px;
  height: 25px;
  z-index: 2;
`

const PageContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`
const TextContainer = styled.div`
    color: #efefef;
    font-size: 18px;
    font-weight: 500;
    width: ${longInput};
    margin-bottom: 20px;
`
const RecoveryText = styled.span`
  ${tw`
      text-white 
  `}
  margin-left: 15px;
  width: 100%;
  font-size: 25px;
  font-weight: 600;
`
const MarginDiv = styled.div`
  margin-top: 10px;
`

const CHANGE_PASSWORD = gql`
  mutation changePassword($data: String!){
    changePassword(data: $data)
  }
`

const RowDiv = styled.div`
    ${tw`
      flex
      items-center
    `}
    margin-top: 30px;
  margin-left: 20px;
`

export default function ChangePass(){
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const [passInfo, setPassInfo] = React.useState({
    password: "",
    confirmPassword: ""
  })
  const [validation, setValidity] = React.useState({
    validPassword: true,
    samePassword: true
  });
  const [error, setError] = React.useState(false)

  const [changePassword, {loading: loadingPass, data: dataPass}] = useMutation(CHANGE_PASSWORD)

  React.useEffect( () => {
    if (passInfo.password === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          validPassword: true
        })
      });
    }
    else{
      setValidity(prevVal => {
        return({
          ...prevVal,
          validPassword: checkPassword(passInfo.password)
        })
      });
    }
  }, [passInfo.password]);


  //checks if the two passwords match
  React.useEffect( () => {
    if (passInfo.confirmPassword === ""){
      setValidity(prevVal => {
        return({
          ...prevVal,
          samePassword: true
        })
      });
    }
    else{
      setValidity(prevVal => {
        return({
          ...prevVal,
          samePassword: passInfo.password === passInfo.confirmPassword
        })
      });
    }
  }, [passInfo.confirmPassword])

  function handleInput(event){
    const {name, value} = event.target;
    setPassInfo( prevUser => {
      return(
        {
          ...prevUser,
          [name]: value
        }
      )
    });
  };

  function checkPassword(str){
    const hasNumber = /\d/.test(str);
    const hasLetter = /[a-zA-Z]/.test(str);
    const hasSpecialSymbol = /[^a-zA-Z0-9]/.test(str);
    const minLength = str.length >= 8;

    return hasNumber && hasLetter && hasSpecialSymbol && minLength;
  }

  function handleNewPassword(event){
    event.preventDefault();

    try{
      changePassword({
        variables: {
          data: encryptData({password: passInfo.password})
        }
      });
      setPassInfo({
        password: "",
        confirmPassword: ""

      })
    }
    catch(error){
      setError(true)
      setPassInfo({
        password: "",
        confirmPassword: ""
      });
      setTimeout( () => {
        setError(false)
      }, 2000)
    }
  }
  return (
    <div>
      <RowDiv>
        <Arrow onClick={() => navigate('/profilo')}>
          <img src={back_arrow} />
        </Arrow>
        <RecoveryText>
          {t('newPasswordPage.pageTitle')}
        </RecoveryText>
      </RowDiv>
      <PageContainer onSubmit={handleNewPassword}>
        <TextContainer>
          {t('newPasswordPage.instructionsText')}
        </TextContainer>
        <FormField
          icon={lock}
          width={longInput}
          name={t('newPasswordPage.passwordField')}
          pHolder={t('newPasswordPage.passwordPlaceholder')}
          inputName="password"
          value={passInfo.password}
          handler={handleInput}
          type="password"
        />
        {!validation.validPassword && 
          <ErrorMessage
            width={longInput}
            left={true}
            text={t('newPasswordPage.passwordValidationError')}
            ul={true}
            ulOptions={t('RegistrationPage.passwordRequirements', { returnObjects: true })}
          />
        }
        <FormField
          icon={lock}
          width={longInput}
          name={t('newPasswordPage.confirmPasswordField')}
          pHolder={t('newPasswordPage.confirmPasswordPlaceholder')}
          inputName="confirmPassword"
          value={passInfo.confirmPassword}
          handler={handleInput}
          type="password"
        />
        {!validation.samePassword && 
          <ErrorMessage
            width={longInput}
            left={true}
            text={t('newPasswordPage.passwordMismatchError')}
          />
        }
        <MainButton
          active={passInfo.password && validation.validPassword && passInfo.confirmPassword && validation.samePassword}
          disabled={passInfo.password && validation.validPassword && passInfo.confirmPassword && validation.samePassword}
          text={t('newPasswordPage.saveButtonText')}
          type="submit"
        />
        <MarginDiv />
        {(dataPass && dataPass.changePassword) && 
          <CorrectPopUp text={t('newPasswordPage.passwordChangeSuccess')} />
        }
        {error && 
          <ErrorPopUp text={t('newPasswordPage.passwordChangeError')} />
        }
      </PageContainer>
    </div>
  );
}