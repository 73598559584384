import React, { Fragment } from "react";
import styled from "styled-components"
import tw from "twin.macro";
import FormField from "./FormField.jsx";
import { FormRow } from "../styledElements";
import MainButton from "./MainButton";
import { loginInput, longInput, mainPurple, mainFucsia, mainBackground, secondaryText, shoeSizes, secondaryBackground, formatDateCorrectly } from "../const.js";
import {  shoeSize } from "../const.js";
import NewShoeForm from "./NewShoeForm.jsx";
import CDatePicker from "./CDatePicker.jsx";
import plus from "../icons/plus.png"
import TagList from "./TagList.jsx";
import check from "../icons/check.png"
import 'dayjs/locale/zh-cn.js'
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import CorrectPopUp from "./CorrectPopUp.jsx";
import search_icon from "../icons/search_icon.png"
import x_icon from "../icons/x_icon.png"
import TypesFiltering from "./TypesFiltering.jsx";
import roundAlert from "../icons/roundAlert.png";
import { actionTypes, useGlobalState } from "../GlobalStateContext";
import ErrorPopUp from "./ErrorPopUp.jsx";
import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import sneaker_icon from "../icons/shoe_icon.png"
import watch_icon from "../icons/watch_icon.png"
import generic_icon from "../icons/generic.png"
import hoodie_icon from "../icons/hoodie_icon.png"
import addIcon from "../icons/addImage_icon.png"
import { useTranslation } from "react-i18next";
import i18n from "../i18n";


const CHANGE_ITEM = gql`
  mutation changeItem($itemId: Float!, $args: ChangeItemArgs!, $file: Upload){
    changeItem(itemId: $itemId, args: $args, file: $file){
      id
      name
      buyPrice
      imageUrl
      expectedSellPrice
      sellPrice
      saleCosts
      sellDate
      type
      buyDate
      influencingGoals{
        name
      }
      tags{
        text
      }
      shoe{
        sizeEU
      }
      watch{
        modelNumber
        serialNumber
      }
      sneaker{
        SKU
        ShoeImage
        ShoeName
      }
    }
  }
`

const TodoButton = styled.button`
  @keyframes pop{
    0%{
      width: 35px;
      height: 35px;
      background: transparent;
    }
    50%{
      width: 40px;
      height: 40px;
      background: ${mainPurple};
    }
    100%{
      width: 35px;
      height: 35px;
      background: transparent;
    }
  }

  @keyframes grow{
    25%{
      transform: scale(1.2);
      background: ${mainPurple};
    }
  }

  ${tw`
      flex
      justify-center
      items-center
  `}
  width: 35px;
  height: 35px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${mainPurple};
  background: transparent;
  margin-top: -20px;
  ${props => props.animate && 'animation: grow 0.5s ease-out;'}
  
  img {
    max-width: 20px;
    max-height: 20px;
  }
`

const SelectionText = styled.div`
  color: ${secondaryText};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
  margin-left: 10px;
`

const FormContainer = styled.form`
  ${tw`
      mt-1
      px-7
      py-2
      flex
      flex-col
  `}
  padding-bottom: 200px;
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .specialRow{
    align-items: end;
  }
animation: appear 0.2s ease-in-out;
`

const NoItems = styled.div`
  @keyframes appear{
    0%{
      transform: TranslateY(20px);
      opacity: 0;
    }
    100%{
      transform: TranslateY(0px);
      opacity: 1;
    }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }

  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      justify-center
      items-center
  `}

  margin-top: 30px;

  animation: appear 0.5s ease-in-out;

  span {
    font-size: 14px;
  }

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    animation: shake 0.5s ease-in-out 0.5s;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 90%;
  background: black;
  padding: 18px 10px;
  border-radius: 15px;
  text-align: center
`

const FlexContainer = styled.div`
  ${tw`
      flex
      w-full
      justify-center
  `}
`

const SneakerImage= styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;

  img{
    width: 60%;
    height: auto;
    border-radius: 20px;
  }
`

const FakeRadio = styled.div`
  ${tw`
      mr-2 
  `}
  width: 15px;
  height: 15px;
  background: transparent;
  border-width: 1px;
  border-color: ${mainPurple};
`

const RadioContainer = styled.div`
  ${tw`
      flex
      flex-row
      items-center
      justify-center
      text-white
      mb-5
      w-full
  `}
  font-size: 16px;
`

const RadioActive = styled.div`
  ${tw`
      flex
      justify-center
      items-center
      mr-2
  `}
  width: 15px;
  height: 15px;
  background: linear-gradient(to right, ${mainPurple}, ${mainFucsia});

  img {
    width: 10px;
    height: 10px;
  }
`

const GoalsTitle = styled.div`
font-size: 15px;
margin-bottom: 15px;
margin-top: -20px;
margin-left: 5px;
font-weight: 500;
color: white;
`

const GoalList = styled.div`
  display: flex;
  color: white;
  flex-wrap: wrap;
`

const SelectGoals = styled.div`
  margin-top: 10px;
`

const Goal = styled.div`
  ${tw`
      py-1
      px-3
  `}
  font-size: 14px;
  border-radius: 10px;
  background: transparent;
  ${props => props.active ? 'border: 1px solid #00dadacc;' : `border: 1px solid black;`}
  margin: 5px 5px;
  ${props => props.active && 'color: #00dada;'}
`


const FlexRow = styled.div`
  ${tw`
      flex
      flex-row
      w-full
      justify-between
      items-center
      mt-5
  `}
`

const ChoicePopUp = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      fixed
  `}
  border: 1px solid #888888;
  top: 40%;
  border-radius: 15px;
  width: 300px;

  background-color: #242424;

  .textDiv{
    color: ${secondaryText};
    text-align: center;
    font-size: 16px;
    padding: 15px 15px;
  }

  .buttonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .choiceButton{
    width: 50%;
    color: #00dada; 
    font-size: 18px;
    font-weight: 600;
    padding: 5px 10px;
    border: 1px solid #888888;
    background-color: ${mainBackground};
  }

  .left{
    border-bottom-left-radius: 15px;

  }
  .right{
    border-bottom-right-radius: 15px;
    
  }
`

const ChoiceContainer = styled.div`
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${tw`
      flex
      flex-col
      w-full
      items-center
  `}

  animation: appear 0.5s ease-in-out;
`

const SneakersContainer = styled.div`
  color: white;
  margin-top: 10px;
  padding-inline: 20px;
`

const Card = styled.div`
  ${tw`
      flex
      flex-col
  `}
  border-radius: 15px;
  border: 1px outset ${secondaryText};
  margin-bottom: 20px;
`
const Transaction = styled.div`
  ${tw`
      flex
  `}
  align-items: center;
  height: 61px;
  width: 100%;
`
const TransactionDesc = styled.div`
  ${tw`
      flex
      flex-col
      ml-3
  `}

  width: 80%;
  .item_name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-height: 1.1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    font-size: 16px;
    width: 95%;
  }
`
const DatabaseShoe = styled.img`
  width: 80px;
  height: 60px;
  border-top-left-radius: 15px;
  ${props => !props.roundImg && 'border-bottom-left-radius: 15px;'}
`

const SearchContainer = styled.div`
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${tw`
      flex
      flex-col
      justify-center
      items-center
  `}
  margin-top: 40px;
  input::placeholder{
    color: rgba(255,255,255,0.8);
  }
  animation: appear 0.2s ease-in-out;
`

const TypesContainer = styled.div`
  ${tw`
      flex
      flex-row
      w-full
      justify-around
      mb-5
  `}
`

const GoBack = styled.div`
  ${tw`
      flex
      py-1
      px-3
      justify-between
      items-center
  `}
  font-size: 14px;
  color: white;
  border-radius: 10px;
  border: 1px solid #00dada;
  margin-bottom: 15px;

  img{
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
`

const SearchInputContainer = styled.div`
  position: relative;
  width: 70%;

  label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  label img {
    height 22px;
    width: 22px
  }
`


const SearchInput = styled.input`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 10px;
  padding-left: 45px;
  color: rgba(255,255,255,0.8);
  border-left: 1px solid rgba(255,255,255,0.8);
  border-bottom: 1px solid rgba(255,255,255,0.8);
  border-right: 1px solid rgba(255,255,255,0.8);
  background: transparent;

  &:focus {
    outline: none; /* Remove the default focus outline */
    background: transparent; /* Set the background to transparent */
    border-left: 2px solid aqua;
    border-bottom: 2px solid aqua;
    border-right: 2px solid aqua;
  }
`

const ChoiceSentence = styled.div`
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;

  .sentence{
    width: 240px;
    border-bottom: 1px solid white;
    line-height: 0.2em;
    margin: 10px 20px;
  }

  .lines{
    background: ${mainBackground};
    padding: 0 10px;
  }
`

const ChoiceButton = styled.div`
  border: 1px solid #00dada;
  background: transparent;
  color: #00dada;
  font-size: 18px;
  width: 220px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`

const AnimationContainer = styled.div`
  @keyframes appear{
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  animation: appear 0.2s ease-in-out;
`

const ImageUploadContainer = styled.div`
    ${tw`
        w-full
        flex
        justify-center
        mb-3
      `}
`



const AddImageContainer = styled.div`
    ${tw`
        flex
        justify-center
        my-2
        items-center
        flex-col
      `}
      position: relative;
      background: ${secondaryBackground};
      width: 130px;
      height: 130px;
      border-radius: 50%;
      padding: 20px;
      border: 1px solid ${secondaryText};

      .addImage{
        position: absolute;
        bottom: -5px;
        right: -10px;
        z-index: 10;
        width: 35px;
        height: 35px;
      }
`

const SelectedImageContainer = styled.div`
  ${tw`
    flex
    justify-center
    my-2
    items-center
    flex-col
  `}
  position: relative;
  background: ${secondaryBackground};
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 1px solid ${secondaryText};

  .addImage {
    position: absolute;
    bottom: -5px;
    right: -10px;
    z-index: 10;
    width: 35px;
    height: 35px;
  }

  .selectedImage {
    width: 130px;
    height: 130px;
    border-radius: 50%; /* Ensures the image matches the container's circular shape */
  }
`;


const IconRow = styled.div`
      ${tw`
          flex
          justify-around
          w-full
          py-2
        `}

    img{
        width: 30px;
        height: 30px;
    }
`


export default function ChangeItem(props){
  const { goals, item, changeFunction } = props;

  const [changeCorrect, setCorrectChange] = React.useState(false);
  const [changeError, setErrorChange] = React.useState(false);

  const [modifyItem, {loading : loadingChange, error: errorChange, data: dataChange}] = useMutation(CHANGE_ITEM, {
    onError: (error) => {
      setErrorChange(true);
      setTimeout( () => {
        setErrorChange(false);
      }, 1500)
    },
    onCompleted: () => {
      setCorrectChange(true);
      setTimeout( () => {
        setCorrectChange(false);
        changeFunction();
      }, 1500)
    }
  });

  const [newItem, setItem] = React.useState({
    name: item.name,
    buyPrice: item.buyPrice,
    sellPrice: item.sellPrice,
    saleCosts: item.saleCosts,
    expectedSellPrice: item.expectedSellPrice,
    buyDate: item.buyDate || "",
    sellDate: item.sellDate || "",
    type: item.type,
    sizeEu: item.shoe ? item.shoe.sizeEU : null,
    modelNumber: item.watch ? item.watch.modelNumber : "",
    serialNumber: item.watch ? item.watch.serialNumber : "",
    gender: item.shoe ? item.shoe.gender : null,
    tags: item.tags ? [...item.tags.map(tag => tag.text)] : [],
    alreadySold: item.sellPrice
  })

  const [itemAdded, setAdded] = React.useState(false);
  const [itemNotAdded, setNotAdded] = React.useState(false);
  const [active, setActive] = React.useState(true);
  const [activeKey, setKey] = React.useState(item.type == 'shoe' ? item.shoe.gender : null);
  const [imageChanged, setImageChanged] = React.useState(false)
  const [itemImage, setItemImage] = React.useState(item.imageUrl);
  const [itemImageFile, setItemImageFile] = React.useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          // Calculate the new dimensions maintaining aspect ratio
          const maxDimension = 150;
          let targetWidth = img.width;
          let targetHeight = img.height;
  
          if (img.width > img.height) {
            targetWidth = maxDimension;
            targetHeight = (img.height / img.width) * maxDimension;
          } else {
            targetHeight = maxDimension;
            targetWidth = (img.width / img.height) * maxDimension;
          }
  
          canvas.width = targetWidth;
          canvas.height = targetHeight;
  
          // Draw the resized image onto the canvas
          ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  
          // Convert canvas to blob for compression
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const resizedImageUrl = URL.createObjectURL(blob);
                setItemImage((prev) => {
                  if (prev) URL.revokeObjectURL(prev); // Revoke old URL
                  return resizedImageUrl;
                });
                setItemImageFile(blob); // Blob for backend upload
                setImageChanged(true);
              }
            },
            "image/jpeg", // Output format
            0.8 // Compression quality
          );
        };
  
        img.src = e.target.result;
      };
  
      reader.readAsDataURL(file);
    }
  };

  React.useEffect(() => {
    if(item.type == 'shoe'){
      if(!shoeSizes[activeKey].includes(shoeSizeEU)) setShoeSize(shoeSizes[activeKey][0]);
      setItem(prevItem => ({
        ...prevItem,
        gender: activeKey
      }))
    }
  }, [activeKey])

  const [shoeSizeEU, setShoeSize] = React.useState( item.type == 'shoe' ? item.shoe.sizeEU : null);
  const [currentTag, setTag] = React.useState("");
  const [animate, setAnimate] = React.useState(false);
  const [date, setDate] = React.useState(dayjs(item.buyDate, "YYYY-MM-DD"));
  const [dateSell, setSell] = React.useState(item.sellDate && dayjs(item.sellDate, "YYYY-MM-DD"));
  const [goalsUpdate, setGoals] = React.useState(item.influencingGoals ? [...item.influencingGoals.map(goal => goal.name)] : []);

  function handleTag(event){
    setTag(event.target.value);
  }

  React.useEffect( () => {
    if (date){
      const formattedDate = formatDateCorrectly(date, i18n.language);
      setItem( prevItem => {
        return({
          ...prevItem,
          buyDate: formattedDate
        })
      })
    }
  }, [date])

  React.useEffect( () => {
    if (dateSell){
      const formattedDate = formatDateCorrectly(dateSell, i18n.language);
      setItem( prevItem => {
        return({
          ...prevItem,
          sellDate: formattedDate
        })
      })
    }
  }, [dateSell])

  React.useEffect( () => {
    if (shoeSizeEU)
      setItem(prevItem => {
        return({
          ...prevItem,
          sizeEu: shoeSizeEU
        })
      })
  }, [shoeSizeEU])

  function addTag(){
    if(currentTag){
      setItem( prevItem => {
        return({
          ...prevItem,
          tags: [...prevItem.tags, currentTag]
        })
      })
      setTag("");
      setAnimate(true);
      setTimeout( () => {
        setAnimate(false);
      }, 1000);
    }
  }

  function deleteTag(index){
    const newTags = [...newItem.tags];
    newTags.splice(index, 1);
    setItem( prevItem => {
      return({
        ...prevItem,
        tags: [...newTags]
      })
    })
  }

  function handleInput(event){
    const {name, value} = event.target;
    setItem( prevItem => {
      return(
        {
          ...prevItem,
          [name]: value
        }
      )
    });
  }

  function changeSold(){
    if (newItem.alreadySold){
      setItem( prevItem => {
        return({
          ...prevItem,
          sellPrice: null,
          sellDate: ""
        })
      })
      setSell("");
    }
    setItem( prevItem => {
      return({
        ...prevItem,
        alreadySold: !prevItem.alreadySold
      })
    })
  }

  async function handleSubmit(event){
    event.preventDefault();
    var changeObject = {
      newBuyPrice: null,
      newExpectedSellPrice: null,
      newSellPrice: null,
      newSaleCosts: null,
      newBuyDate: null,
      newSellDate: null,
      goalsUpdate: [...goalsUpdate],
      newTags: null,
      newSize: null,
      newGender: null
    };
    if(item.name !== newItem.name) changeObject.newName = newItem.name;
    if(item.buyPrice !== newItem.buyPrice) changeObject.newBuyPrice = parseInt(newItem.buyPrice);
    if(item.sellPrice !== newItem.sellPrice) changeObject.newSellPrice = parseInt(newItem.sellPrice)
    if(item.expectedSellPrice !== newItem.expectedSellPrice) changeObject.newExpectedSellPrice = parseInt(newItem.expectedSellPrice)
    if(item.saleCosts !== newItem.saleCosts) changeObject.newSaleCosts = parseInt(newItem.saleCosts)
    //comparing the tags
    const tags = item.tags.map(tag => tag.text);
    if(tags.length !== newItem.tags.length){
      changeObject.newTags = [...newItem.tags]
    } else{
      var sortedTags = tags.sort();
      var newSortedTags = newItem.tags.sort();
      for (let i = 0; i < sortedTags.length; i++) {
        // If any elements differ, arrays are not equal
        if (sortedTags[i] !== newSortedTags[i]) {
          changeObject.newTags = [...newItem.tags]
          break;
        }
      }
    }

    if(item.buyDate !== newItem.buyDate) changeObject.newBuyDate = newItem.buyDate
    if(item.sellDate !== newItem.sellDate) changeObject.newSellDate = newItem.sellDate
    
    if(item.type == 'shoe'){
      if(item.shoe.sizeEU !== shoeSizeEU) changeObject.newSize = shoeSizeEU;
      if(item.shoe.gender !== activeKey) changeObject.newGender = activeKey;
    }
    modifyItem({
      variables: {
        args: changeObject,
        itemId: item.id,
        file: itemImageFile
      }
    })
  }

  function changeGoal(event){
    const {id} = event.target;
    if (goalsUpdate.length > 0 && goalsUpdate.includes(id)){
      setGoals(prevGoal => prevGoal.filter(item => item !== id))
    }
    else{
      setGoals(prevGoal => [...prevGoal, id]);
    }
  }

  function clearForm(){
    setItem({
      name: "",
      buyPrice: null,
      sellPrice: null,
      expectedSellPrice: null,
      buyDate: "",
      sellDate: null,
      type: "",
      sizeEu: "",
      modelNumber: "",
      serialNumber: "",
      gender: "",
      tags: [],
      alreadySold: false
    });
    setShoeSize(shoeSize[0]);
    setTag("");
    setDate(null);
    setSell(null);
    setGoals([]);
  }


  const inputRef = React.useRef(null);
  
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const {t} = useTranslation();

  return(
    <div>
        <FormContainer onSubmit={handleSubmit}>
          <ImageUploadContainer onClick={handleClick}>
            {!itemImage && <AddImageContainer>
              <IconRow>
                <img src={sneaker_icon} alt="Sneaker icon" />
                <img src={watch_icon} alt="Watch icon" />
              </IconRow>
              <IconRow>
                <img src={generic_icon} alt="Generic icon" />
                <img src={hoodie_icon} alt="Hoodie icon" />
              </IconRow>
              <img src={addIcon} alt="Add image icon" className="addImage" />
            </AddImageContainer>}
            {itemImage && <SelectedImageContainer>
              <img src={itemImage} alt="Item Image" className="selectedImage"/>
              <img src={addIcon} alt="Add image icon" className="addImage" />
              </SelectedImageContainer>}
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </ImageUploadContainer>
          <FormField  
            width={longInput}
            name={t('FormComponent.changeName')}
            pHolder={t('FormComponent.itemNamePlaceholder')}
            inputName="name"
            value={newItem.name}
            handler={handleInput}
          />
          <FormRow>
            <FormField width={longInput} name={t('FormComponent.changeBuyPrice')} pHolder="€" inputName="buyPrice" value={newItem.buyPrice} handler={handleInput} fontDim="15px" type="number"/>
          </FormRow>
          <FormRow>
            <FormField width={longInput} name={t('FormComponent.changeSellPrice')} pHolder="€" inputName="expectedSellPrice" value={newItem.expectedSellPrice} handler={handleInput} fontDim="15px" type="number"/>
          </FormRow>
          <FormRow className="specialRow">
            <CDatePicker title={t('FormComponent.changePurchaseDate')} date={date} setDate={setDate}/>
          </FormRow>
          {newItem.type == "shoe" && 
            <AnimationContainer>
              <NewShoeForm activeKey={activeKey} setKey={setKey} select={setShoeSize} currSize={shoeSizeEU} />
            </AnimationContainer>
          }
          {newItem.type == "watch" && 
            <AnimationContainer>
              <FormRow>
                <FormField width={longInput} name={t('FormComponent.changeModelNumber')} pHolder="" inputName="modelNumber" value={newItem.modelNumber} handler={handleInput} fontDim="15px"/>
              </FormRow>
              <FormRow>
                <FormField width={longInput} name={t('FormComponent.changeSerialNumber')} pHolder="" inputName="serialNumber" value={newItem.serialNumber} handler={handleInput} fontDim="15px"/>
              </FormRow>
            </AnimationContainer>
          }
          <FlexRow>
            <FormField width={loginInput} name={t('FormComponent.modifyTag')} pHolder={t('FormComponent.tagPlaceholder')} inputName="tag" value={currentTag} handler={handleTag} requiredF={true}/>
            <TodoButton onClick={addTag} animate={animate} type="button">
              <img src={plus} />
            </TodoButton>
          </FlexRow>
          {newItem.tags.length > 0 && <TagList tags={newItem.tags} func={deleteTag}/>}
          {item.sellPrice && 
            <AnimationContainer>
              <FormRow>
                <FormField width={longInput} name={t('FormComponent.changeSellPriceText')} pHolder={t('FormComponent.salePricePlaceholder')} inputName="sellPrice" value={newItem.sellPrice} handler={handleInput} fontDim="15px" type="number"/>
              </FormRow>
              <FormRow>
                <FormField width={longInput} name={t('FormComponent.changeSaleCosts')} pHolder={t('FormComponent.saleCostsPlaceholder')} inputName="saleCosts" value={newItem.saleCosts} handler={handleInput} fontDim="15px" type="number"/>
              </FormRow>
              <FormRow>
                <CDatePicker title={t('FormComponent.changeSaleDate')} date={dateSell} setDate={setSell}/>
              </FormRow>
            </AnimationContainer>
          }
          {(goals && item.sellPrice) && 
            <SelectGoals>
              <GoalsTitle>{t('FormComponent.selectGoalsTitle')}</GoalsTitle>
              <GoalList>
                {goals.map((goal, index) => (
                  <Goal id={goal.name} key={index} onClick={changeGoal} active={goalsUpdate.includes(goal.name)}>{goal.name}</Goal>
                ))}
              </GoalList>
            </SelectGoals>
          }
          <MainButton active={active} text={t('FormComponent.saveButtonText')} onClick={handleSubmit} type="submit"/>
          {changeCorrect && 
            <CorrectPopUp text={t('FormComponent.itemModifiedSuccess')} />
          }
          {changeError && 
            <ErrorPopUp text={t('FormComponent.itemNotModifiedError')} />
          }
        </FormContainer>
    </div>
  ) 
}
