import Chart from 'chart.js/auto';
import React, { useCallback, useRef } from 'react';
import { Doughnut, Line, Bar, Pie } from 'react-chartjs-2';
import styled from "styled-components"
import MainButton from './MainButton';
import { mainBackground } from '../const';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';

Chart.register(ChartDataLabels);


const GeneratorContainer = styled.div`
  margin-top: 15px;
`
const ChartContainer = styled.div`
  margin-top: 30px;
  opacity: 0;
`

export default function GenerateMonthReport(props) {
  const {lineDataSales, lineDataPurchases, barSales, barPurchases, onImageDownload, tagSales, onDownloadStart} = props;

  let linePurRef = useRef(null);
  let barPurRef = useRef(null);
  let lineSalRef = useRef(null);
  let barSalRef = useRef(null);
  let pieTagRef = useRef(null);


  const downloadImage = useCallback(() => {
    const barPurImage = barPurRef.current.toBase64Image();
    const linePurImage = linePurRef.current.toBase64Image();
    const barSalImage = barSalRef.current.toBase64Image();
    const lineSalImage = lineSalRef.current.toBase64Image();
    const pieTagImage = pieTagRef.current.toBase64Image();
    onImageDownload(barPurImage, linePurImage, barSalImage, lineSalImage, null, pieTagImage);
    onDownloadStart();
  }, [onImageDownload]);

  const colors = ['#33ccff', '#6600cc', '#967bb6','#708090','#aa0000' ,'#008080'];
  const tagColors = ["#001F3F", "#003366", "#0056B3", "#0074CC", "#0099FF", "#00BFFF", "#3399FF", "#66B2FF", "#99CCFF", "#C6E2FF"]

  const pieDataTags = {
    labels: tagSales ? tagSales.map( (obj) => obj.tagText) : [],
    datasets: [{
      labels: 'Sales',
      data: tagSales? tagSales.map( (obj) => obj.total) : [],
      backgroundColor: tagSales ? tagColors.slice(0, tagSales.length) : 'black',
      borderColor: 'white',
      borderWidth: 2
    }]
  }

  const pieTagOptions = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: 'black',
          boxWidth: 15,
          font: {
            size: 18
          }
        }
      },
      datalabels: {
        display: false
      }
    } 
  }

  const dataLinePurchases = {
    labels: lineDataPurchases? lineDataPurchases.map( (obj) => obj.label) : [],
    datasets: [{
      labels: 'Sales',
      data: lineDataPurchases? lineDataPurchases.map( (obj) => obj.data) : [],
      backgroundColor: 'rgb(0,255,255)',
      borderColor: 'rgba(0,255,255, 0.7)',
      tension: 0.4,
      fill: true,
      borderWidth: 2,
      pointRadius: 0,
      backgroundColor: (ctx) => {
        const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, 180); // Create a vertical gradient
        gradient.addColorStop(0, 'rgba(0, 255, 255, 0.6)'); // Start color (with opacity)
        gradient.addColorStop(1.0, 'rgba(255, 255, 255, 1)'); // End color (fully transparent)
        return gradient; // Set the gradient as the background color
      },
    }]
  };

  const dataLineSales = {
    labels: lineDataSales? lineDataSales.map( (obj) => obj.label) : [],
    datasets: [{
      labels: 'Sales',
      data: lineDataSales? lineDataSales.map( (obj) => obj.data) : [],
      backgroundColor: 'rgb(0,255,255)',
      borderColor: 'rgba(0,255,255, 0.7)',
      tension: 0.4,
      fill: true,
      borderWidth: 2,
      pointRadius: 0,
      backgroundColor: (ctx) => {
        const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, 180); // Create a vertical gradient
        gradient.addColorStop(0, 'rgba(0, 255, 255, 0.6)'); // Start color (with opacity)
        gradient.addColorStop(1.0, 'rgba(255, 255, 255, 1)'); // End color (fully transparent)
        return gradient; // Set the gradient as the background color
      },
    }]
  };

  const optionsLine = {
    plugins: {
      legend: false,
      datalabels: {
        display: false
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        }
      },
      y: {
        maxTicksLimit: 2,
        suggestedMin: 0,
        border:{dash: [4, 4]},
        grid: {
          color: '#cccccc',
          tickBorderDash: [4, 4], // also for the tick, if long enough
          tickWidth: 1,
          offset: true,
          drawTicks: true, // true is default 
          drawOnChartArea: true // true is default
        },
        ticks: {
          color: 'grey'
        }
      }
    }
  }


  const barDataSales = {
    labels: barSales ? barSales.map( obj => obj.type) : [],
    datasets: [
      {
        label: 'Dataset 1',
        backgroundColor: ['#00dada', '#006d6d', '#001818'],
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: barSales ? barSales.map( obj => obj.total) : [],
        border: 0,
      },
    ],
  };

  const barDataPurchases = {
    labels: barPurchases ? barPurchases.map( obj => obj.type) : [],
    datasets: [
      {
        label: 'Dataset 1',
        backgroundColor: ['#00dada', '#006d6d', '#001818'],
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: barPurchases ? barPurchases.map( obj => obj.total) : [],
        border: 0,
      },
    ],
  };

  const barOptions = {
    categoryPercentage: 1.05, // here 
            barPercentage: 1.0,  // here
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    layout: {
      padding: {
        right: 60, // Adjust the top padding to create space for labels
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      datalabels: {
        anchor: 'end', // Set the anchor point to 'end' (top of the bar)
        align: 'end', // Align the labels to the end of the bar
        color: 'black', // Label text color
        formatter: (value, context) => {
          return `€${value}`; // Display the value on top of the bar
        },
        offset: 5,
      },
    },
    scales: {
      x: {
        display: false, // Hide the x-axis
      },
      y: {
        beginAtZero: true,
        grid:{
          display: false
        }
      }
    }
  };

  const { t } = useTranslation();

  return(
    <GeneratorContainer>
      <MainButton active={true} text={t('pdfMonthReport.downloadReport')} onClickFunction={downloadImage}/>
      <ChartContainer>
        {barSales && <Bar ref={barSalRef} data={barDataSales} options={barOptions}/>}
        {lineDataSales && <Line ref={lineSalRef} data={dataLineSales} options={optionsLine}/>}
        {barPurchases && <Bar ref={barPurRef} data={barDataPurchases} options={barOptions}/>}
        {lineDataPurchases && <Line ref={linePurRef} data={dataLinePurchases} options={optionsLine}/>}
        {tagSales && <Pie ref={pieTagRef} data={pieDataTags} options={pieTagOptions}/>}
      </ChartContainer>
    </GeneratorContainer>
  )
}