import styled from "styled-components"
import tw from "twin.macro";
import lock_icon from "../icons/lock_icon.png"
import logout_icon from "../icons/logout_icon.png"
import edit_icon from "../icons/edit_icon.png"
import coin_icon from "../icons/coin_icon.png"
import document_icon from "../icons/document_icon.png"
import { useNavigate } from "react-router-dom";
import cookies from "../help/cookie";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { mainBackground, secondaryBackground } from "../const";

const OptionsContainer = styled.div`
  ${tw`
      flex
  `}
  flex-direction: column;
  margin-top: 5px;
`

const Option = styled.div`
  ${tw`
      flex
      flex-row
      w-full
  `}
  align-items: center;
  font-size: 16px;
  background: #242424;
  height: 55px;
  border-bottom: 2px solid #181818;

  color: white;
  font-weight: 400;

  img{
    width: 25px;
    margin-right: 5%;
    margin-left: 20px;
  }
`

const SmallTitle = styled.div`
  ${tw`
      text-white
      font-semibold
  `}
  margin-top: 15px;
  margin-left: 20px;
  font-size: 18px;
  margin-bottom: 15px;
  
`

const LanguageToggleContainer = styled.div`
  ${tw`
    flex
    items-center
    justify-between
    w-full
    rounded-lg
    my-3
    py-3
    cursor-pointer
  `}
  padding-right: 20px;
  background: ${secondaryBackground};
  border: 1px solid ${mainBackground};

`;

const ToggleLabel = styled.span`
  ${tw`
    text-white
    flex
    items-center
    font-medium
  `}
  font-size: 16px;
  padding-left: 20px;
`;

const ToggleWrapper = styled.div`
  ${tw`
    relative
    w-14
    h-7
    rounded-full
    transition-all
  `}
  background: ${({ selected }) => (selected ? "#006d6d" : "#505050")};
`;

const ToggleCircle = styled.div`
  ${tw`
    absolute
    top-1
    left-1
    w-5
    h-5
    bg-white
    rounded-full
    transition-all
  `}
  ${({ selected }) => selected && "transform: translateX(120%);"}
`;

export default function ProfileOptions(){
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(localStorage.getItem("language") || "en");

  // Function to change language
  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang); // Save to localStorage
  };

  useEffect(() => {
    i18n.changeLanguage(language); // Apply saved language on mount
  }, [language, i18n]);

  return(
    <OptionsContainer>
      <SmallTitle>
        {t('ProfileOptions.language')}
      </SmallTitle>
      <LanguageToggleContainer onClick={() => changeLanguage(language === "en" ? "it" : "en")}>
        <ToggleLabel>
          {language === "en" ? "English" : "Italiano"}
        </ToggleLabel>
        <ToggleWrapper selected={language === "it"}>
          <ToggleCircle selected={language === "it"} />
        </ToggleWrapper>
      </LanguageToggleContainer>
      <SmallTitle>
        {t('ProfileOptions.account')}
      </SmallTitle>
      <Option onClick={ () => {
        navigate('/profilo/modificaDati');
      }}>
        <img src={edit_icon}/>
        <span>{t('ProfileOptions.editPersonalData')}</span>
      </Option>
      <Option onClick={ () => {
        navigate('/profilo/cambiaPassword');
      }}>
        <img src={lock_icon}/>
        <p>{t('ProfileOptions.changePassword')}</p>
      </Option>
      <SmallTitle>
        {t('ProfileOptions.subscription')}
      </SmallTitle>
      <Option onClick={ () => {
        navigate('/profilo/gestionePiano');
      }}>
        <img src={coin_icon}/>
        <p>{t('ProfileOptions.manageYourPlan')}</p>
      </Option>
      <SmallTitle>
        {t('ProfileOptions.session')}
      </SmallTitle>
      <Option onClick={ () => {
        cookies.remove('accessToken');
        navigate('/');
      }}>
        <img src={logout_icon}/>
        <p>{t('ProfileOptions.logout')}</p>
      </Option>
    </OptionsContainer>
  )

  
}