import NewItemForm from "../components/NewItemForm";
import Navfixed from "../components/Navfixed";
import React from "react";
import './css_files/WalletPage.css'
import { Tab } from "@headlessui/react"
import NewSaleForm from "../components/NewSaleForm";
import styled from "styled-components"
import { gql, useQuery } from "@apollo/client";
import HeaderFixed from "../components/HeaderFixed";
import { actionTypes, useGlobalState } from "../GlobalStateContext";
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Container = styled.div`
`

const GET_SPECIFIC_GOALS = gql`
  query getSpecificGoals{
    getSpecificGoals{
      name
    }
  }
`

export default function NewItemPage(props){
  const { state, dispatch} = useGlobalState();

  const {loading, error, data, refetch} = useQuery(GET_SPECIFIC_GOALS);

  const [specificGoals, setGoals] = React.useState([]);

  const { t } = useTranslation();

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'newItemData',
    });
  };

  React.useEffect( () => {
    if (data){
      setGoals(data.getSpecificGoals);
    }
  }, [data])

  React.useEffect( ( ) => {
    if(state.dataUpdated.newItemData){
      handleToggleDataUpdate();
      try{
        refetch();
      }
      catch(error){
        console.log(error);
      }
    }
  }, [])
 
  return(
    <Container>
      <HeaderFixed pageTitle={t('NewItemPage.pageTitle')} />
      <Tab.Group defaultIndex={state.itemToSell.index}>
            <Tab.List className=" navigationList flex">
              <Tab className={({ selected }) =>
                    classNames(
                      'w-full text-md tabCustom br',
                      'focus:outline-none',
                      selected
                        ? 'tabSel font-medium'
                        : 'hover:text-white font-normal'
                    )
                  }>{t('NewItemPage.newPurchaseTab')}</Tab>
              <Tab className={({ selected }) =>
                    classNames(
                      'w-full text-md tabCustom',
                      'focus:outline-none',
                      selected
                      ? 'tabSel font-medium'
                      : 'hover:text-white font-normal'
                    )
                  }>{t('NewItemPage.newSaleTab')}</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <NewItemForm goals={specificGoals}/>
              </Tab.Panel>
              <Tab.Panel>
                  <NewSaleForm goals={specificGoals} itemToSell={state.itemToSell.itemInfo}/>
              </Tab.Panel>
            </Tab.Panels>
      </Tab.Group>
      <Navfixed currentPage={"addItem"}/>
    </Container>
  )
}