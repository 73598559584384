import shoe from "./icons/shoe_icon.png"
import watch from "./icons/watch_icon.png"
import generic from "./icons/generic.png"

import moment from 'moment';
import 'moment/locale/en-gb'; // Import English locale
import CryptoJS from "crypto-js";

function italianDate(input, language = 'it') {
    moment.locale(language); // Set locale dynamically
    const today = moment();

    switch (input) {
        case 'week': {
            const startOfWeek = today.clone().startOf('isoWeek');
            const endOfWeek = today.clone().endOf('isoWeek');
            return `${startOfWeek.format('D MMMM')} - ${endOfWeek.format('D MMMM')}`;
        }
        case 'month': {
            const month = today.format('MMMM');
            const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
            return `${capitalizedMonth} ${today.format('YYYY')}`;
        }
        case 'year':
            return today.format('YYYY');
        default:
            return 'Invalid input';
    }
}

export function allDate(totalExpenses) {
  if(totalExpenses.length > 0){
    var dateString1 = totalExpenses[0].date
    var dateString2 = totalExpenses[totalExpenses.length - 1].date
    const date1 = moment(dateString1);
    const date2 = moment(dateString2);
  
    const monthYear1 = date1.format('MMMM YYYY');
    const monthYear2 = date2.format('MMMM YYYY');
  
    return `${monthYear1} - ${monthYear2}`;
  } else{
      const date1 = moment(new Date());
    
      const monthYear1 = date1.format('MMMM YYYY');
    
      return `${monthYear1} - ${monthYear1}`;
  }
}

export default italianDate;


export const mainPurple = "#006d6d";

export const iconColor = "#00B6B6";
export const mainFucsia = "#00dada";

export const secondaryBackground = "#242424"
export const mainBackground= "#181818"

export const secondaryText = "#EFEFEF"

export const mediumInput = "140px"

export const longInput = "300px"

export const loginInput = "260px"

export const typeOptions = ["Generico", "Scarpa", "Orologio"]

export const shoeSize = ["Taglia", 39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5,44, 44.5, 45, 
                        45.5,46, 46.5,47, 47.5];

                        
export const goalTypes = ["Guadagno", "Totale"];

export const goalTypesByLanguage ={ 
  it: ["Guadagno", "Totale"],
  en: ["Profits", "Sales"]
};

export const getGoalType = (type) => {
  if (type === "Guadagno" || type === "Gain"){
    return("gain");
  }
  else{
    return("sales")
  }
}

export const getGoalName = (name) => {
  if (name === "Vendite mensili" || name === "Monthly sales") return "monthlySales"
  else if (name === "Guadagno mensile" || name === "Monthly profits") return "monthlyGain"
  else if (name === "Guadagno annuale" || name === "Yearly profits") return "yearlyGain"
  else if (name === "Vendite annuali" || name === "Yearly sales") return "yearlySales"
}

export const fakeData = [ 
  {
    name: "Nike nome lunghissimo porcodiooooo",
    buyDate: "2023-04-05",
    buyPrice: 150,
    sellDate: null,
    sellPrice: null
  },
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-06-07",
    sellPrice: 280
  },
  {
    name: "Ao so io",
    buyDate: "2023-03-10",
    buyPrice: 1550,
    sellDate: null,
    sellPrice: null
  },
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-09-08",
    sellPrice: 2000
  },
  {
    name: "Nike nome lunghissimo porcodiooooo",
    buyDate: "2023-04-05",
    buyPrice: 150,
    sellDate: null,
    sellPrice: null
  },
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-06-07",
    sellPrice: 280
  },
  {
    name: "Ao so io",
    buyDate: "2023-03-10",
    buyPrice: 1550,
    sellDate: null,
    sellPrice: null
  },
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-09-08",
    sellPrice: 2000
  },
]

export const fakeSold = [
  {
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-06-07",
    sellPrice: 280
  },
{
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-09-08",
    sellPrice: 2000
  },
{
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-06-07",
    sellPrice: 280
  },
{
    name: "Nike nome altrettanto lungo ooooh",
    buyDate: "2023-04-05",
    buyPrice: 200,
    sellDate: "2023-09-08",
    sellPrice: 2000
  },
]

export const fakeUnsold = [
  {
    name: "Nike nome lunghissimo porcodiooooo",
    buyDate: "2023-04-05",
    buyPrice: 150,
    sellDate: null,
    sellPrice: null
  },
{
    name: "Ao so io",
    buyDate: "2023-03-10",
    buyPrice: 1550,
    sellDate: null,
    sellPrice: null
  },
{
    name: "Nike nome lunghissimo porcodiooooo",
    buyDate: "2023-04-05",
    buyPrice: 150,
    sellDate: null,
    sellPrice: null
  },
{
    name: "Ao so io",
    buyDate: "2023-03-10",
    buyPrice: 1550,
    sellDate: null,
    sellPrice: null
  },
]

/**
 * funzioni comuni
 */

export const formatDate = (dateString, lang = 'it-IT') => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat(lang, options).format(date);
  return formattedDate;
};

export const getIcon = (type) => {
  return(type === "shoe" ? shoe : 
    type === "watch" ? watch :
      generic
  );
}

export const getForfettaryTaxes = (total, years) => {
  var percent = 0;
  years > 5 ? percent = 0.15 : percent = 0.05;

  var utile = total * 0.4;

  return({
    taxesToPay: Math.round(utile * 0.05),
    contributi: Math.round(utile * 0.24)
  })
}

export const years = () => {
  const today = new Date();
  const year = today.getFullYear();

  return( [year, year - 1]);
};

export const monthsByLanguage = {
  it: [
    "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", 
    "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
  ],
  en: [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ]
};

// Get month index (1-based) from a month name in the specified language
export const mapMonth = (month, lang = 'it') => {
  
  const months = monthsByLanguage[lang] || monthsByLanguage['it'];
  const index = months.indexOf(month);
  return index >= 0 ? index + 1 : null;
};

// Get month name from a month label (e.g., "01") in the specified language
export const monthStringFromLabel = (monthLabel, lang = 'it') => {
  const months = monthsByLanguage[lang] || monthsByLanguage['it'];
  const monthIndex = parseInt(monthLabel, 10) - 1;
  return months[monthIndex] || "";
};

// Map an array of month labels to month names in the specified language
export const monthFromLabel = (labels, lang = 'it') => {
  return labels.map(label => monthStringFromLabel(label.slice(-2), lang));
};

export const limiteForfettario = 85000;

export const shoeSizes = {
  USMen: [
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12,
    12.5,
    13,
    13.5,
    14,
    14.5,
    15
  ],
  USWomen: [
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12
  ],
  GS: [
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7
  ],
  EU: [30, 30.5, 31, 31.5, 32, 32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47, 47.5, 48, 48.5, 49, 49.5, 50, 50.5, 51]
}

export const timeExpensePeriods = ["Mensile", "Trimestrale", "Semestrale", "Annuale"]

export const expensesPeriodsByLanguage = {
  it: ["Mensile", "Trimestrale", "Semestrale", "Annuale"],
  en: ["Monthly", "Quarterly", "Semiannual", "Annual"]
};


export function stringToArray(inputString) {
  // Use the split method to split the string into an array
  // based on the comma and space (", ") delimiter
  var resultArray = inputString.split(", ");

  // Trim whitespace from each element in the array
  resultArray = resultArray.map(function (element) {
      return element.trim();
  });

  return resultArray;
}

export function calculateExpiryDate(period, dateString) {
  const currentDate = new Date(dateString);
  let expiryDate = new Date(dateString);

  switch (period) {
    case "mensile":
      expiryDate.setMonth(currentDate.getMonth() + 1)
      break;
    case "trimestrale":
      expiryDate.setMonth(currentDate.getMonth() + 3)
      break;
    case "semestrale":
      expiryDate.setMonth(currentDate.getMonth() + 6)
      break;
    case "annuale":
      expiryDate.setMonth(currentDate.getMonth() + 12)
      break;
    default:
      break;
  }
  return expiryDate.toISOString().split('T')[0]; // Return only the date part
}


export function getNextValue(number) {
  const baseValue = 10000;
  const increments = [1, 1.25, 1.5, 1.75, 2];  // 1, 1/4, 1/2, 3/4, full magnitude
  let nextValue = baseValue;

  if (number < baseValue) {
    return baseValue;  // If less than 10,000, return the input number
  }

  // Find the closest next value by scaling the next order of magnitude
  while (nextValue <= number) {
    nextValue *= 2;  // Move to the next order of magnitude
  }

  // Calculate fractions for the next sequence
  for (let i = 1; i < increments.length; i++) {
    const possibleValue = Math.floor(nextValue * increments[i]);
    if (possibleValue > number) {
      return possibleValue;
    }
  }

  return nextValue;
}


export function isSingleExpense(expense){
  if(expense == 'singola' || expense == 'single') return true;
  return false;
}

const lowerExpensePeriods = ["mensile", "trimestrale", "semestrale", "annuale"]

export function getExpensePeriodEnglish(expensePeriod){
  let ind = lowerExpensePeriods.indexOf(expensePeriod);
  return expensesPeriodsByLanguage.en[ind];
}

export function getPeriodFormat(expensePeriod){
  let ind = expensesPeriodsByLanguage.en.indexOf(expensePeriod);
  return lowerExpensePeriods[ind];
}


export function formatDateCorrectly(date, language){
  const d = date.$d instanceof Date ? date.$d : date; // Handle both Day.js and native Date
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit month
  const day = String(d.getDate()).padStart(2, '0'); // Ensure 2-digit day
  return `${year}-${month}-${day}`;
}

export function encryptData(data) {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
    return encrypted;
  }