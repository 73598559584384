import React from "react"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut, Pie } from "react-chartjs-2";
import styled from "styled-components"
import tw from "twin.macro";
import add_icon from "../icons/add_expense.png"
import { secondaryBackground, secondaryText } from "../const";
import { useTranslation } from "react-i18next";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

const PageContainer = styled.div`
  ${tw`
      w-full
      flex
      justify-center
      mt-2
  `}
`

const Container = styled.div`
  width: 180px;
  height: 180px;
  position: relative;
`

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .euro{
    font-size: 0.9em;
    opacity: 0.9;
    margin-left: 3px;
    font-weight: 400;
  }

  .doughnutTitle{
    color: #00dada;
    font-size: 16px;
  }

  .value{
    color: white;
    font-size: 24px;
    font-weight: 600;
  }
  
  z-index: -1;
`

const AddContainer = styled.img`
  position: absolute;
  bottom: 10px;
  right: -40px;

  width: 40px;
  height: 40px;
`


export default function ExpensePageChart(props){
  const colors = ['#33ccff', '#6600cc', '#967bb6','#708090','#aa0000' ,'#008080'];
  
  const {data, total, openNewForm} = props;
  var bColors = [];
  for (let i = 0; i < data.data.length; i++){
    bColors.push(colors[i]);
  }

  const dataP = {
    labels: data.labels,
    datasets: [
      {
        data: data.data,
        backgroundColor: colors,
        borderWidth: 10,
        borderColor: '#181818',
        hoverOffset: 10,
        borderRadius: 5
      }
    ]
  };

  const options = {
    cutout: '65%',
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      }
    }
  };

  const { t } = useTranslation();


  return(
    <PageContainer>
      <Container>
        <Doughnut
          data={dataP}
          options = {options}
        />
        <TextContainer>
          <span className="doughnutTitle">{t('ExpensePageChartComponent.total')}</span>
          <span className="value">{total}<span className="euro">€</span></span>
        </TextContainer>
        <AddContainer src={add_icon} onClick={() => openNewForm()}/>
      </Container>
    </PageContainer>
  )
}