import React from "react";
import Navfixed from "../components/Navfixed";
import styled from "@emotion/styled";
import profile_page_icon from "../icons/profile_page_icon.png"
import tw from "twin.macro";
import ProfileOptions from "../components/ProfileOptions";
import { useNavigate } from "react-router-dom";
import { BlueCircle, GreenCircle, BlackCircle, PulsatingCircle } from "../styledElements";
import HeaderFixed from "../components/HeaderFixed";
import { useTranslation } from 'react-i18next';

const PageContainer = styled.div`
  overflow-x: hidden;
  position: relative;
`

const PageTitle = styled.div`
  ${tw`
      mb-7
      text-white
      font-semibold
  `}
  margin-top: 30px;
  margin-left: 8%;
  font-size: 30px;
  .colored{
    color: #00dada;
  }
`

export default function ProfilePage(){
  const { t } = useTranslation();

  return(
    <PageContainer>
      <HeaderFixed pageTitle={t('Header.profile')} menuPage="PROFILE"/>
      <ProfileOptions />
      <Navfixed />
    </PageContainer>
  )
}