import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import LoadingAnimation from "../components/LoadingAnimation";
import HeaderFixed from "../components/HeaderFixed";
import Navfixed from "../components/Navfixed";
import PDFGenerator from "../components/PDFGenerator";
import { useTranslation } from "react-i18next";


export default function DocumentsPage() {
  const { t } = useTranslation();
  return (
    <div>
      <HeaderFixed pageTitle={t('pdfSelection.pageTitle')} menuPage="DOCUMENTS" />
      <PDFGenerator />
      <Navfixed />
    </div>
  );
}
