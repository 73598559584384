import React from "react";
import HeaderFixed from "../components/HeaderFixed";
import Navfixed from "../components/Navfixed";
import tw from "twin.macro";
import { useQuery, gql } from "@apollo/client";
import styled from "styled-components"
import { FormRow } from "../styledElements";
import { getForfettaryTaxes, mediumInput, secondaryText } from "../const";
import FormField from "../components/FormField";
import { useNavigate } from "react-router-dom";
import MainButton from "../components/MainButton"
import roundAlert from "../icons/roundAlert.png";
import LoadingAnimation from "../components/LoadingAnimation";
import { actionTypes, useGlobalState } from "../GlobalStateContext"
import { useTranslation } from "react-i18next";

const NoItems = styled.div`
  @keyframes appear{
    0%{
      transform: TranslateY(20px);
      opacity: 0;
    }
    100%{
      transform: TranslateY(0px);
      opacity: 1;
    }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }

  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      justify-center
      items-center
  `}

  margin-top: 30px;

  animation: appear 0.5s ease-in-out;

  span {
    font-size: 14px;
  }

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    animation: shake 0.5s ease-in-out 0.5s;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 90%;
  background: black;
  padding: 18px 10px;
  border-radius: 15px;
  text-align: center
`

const BoxRow = styled.div`
  width: 290px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Box = styled.div`
  width: 48%;
  padding: 12px 13px;
  height: 80px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  color: white;
  border: 2px solid black;
  box-shadow: 0px 1px 1px #00b6b6;

  .euro{
    font-size: 0.9em;
    opacity: 0.9;
    margin-left: 2px;
  }

  .title{
    font-weight: 600;
    font-size: 15px;
    color: #00dada;
    line-height: 1.2;
    margin-bottom: 3px;
  }

  .value{
    color:white;
    font-size: 20px;
    margin-left: 3px;
  }
`

const AnnualValues = styled.div`
  ${tw`
      w-full 
  `}
  margin-top: 30px;
`

const BoxContainer = styled.div`
  ${tw`
    w-full 
    flex
    flex-col
    items-center
  `}
`

const SectionTitle = styled.p`
  ${tw`
      text-white
  `}
  font-size: 18px;
  margin-left: 25px;
  font-weight: 600;
`

const GET_TAXES_PREDICTION = gql`
  query getTaxesSales{
    getTaxesSales{
      totalSalesPrediction
      salesYearly
      gainsYearly
    }
  }
`

const ValueBox = styled.div`
  width: 290px;
  height: 80px;
  border: 2px solid black;
  box-shadow: 0px 1px 1px #00b6b6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;

  .boxTitle{
    color: #00dada;
    font-size: 18px;
  }

  .boxValue{
    color: white;
    font-size: 24px;
  }

  .euro{
    font-size: 0.9em;
    opacity: 0.9;
    margin-left: 2px;
  }

  margin-top: 20px;
  background: transparent;
  .boxPercent{
    color: #61DE70;
    font-size: 24px;
    font-weight: 500;
  }
  margin-bottom: 15px;
`

const SubsectionTitle = styled.span`

`

const TaxesContainer = styled.div`
  margin-top: 30px;
`

const TaxesParagraph = styled.p`
  font-size: 14px;
  margin-left: 25px;
  margin-right: 15px;
  color: ${secondaryText};
  line-height: 1.2;
  margin-bottom: 15px;

  a{
    color: #00dada;
}
`

const CalculateButton = styled.button`
  border-radius: 10px;
  font-size: 18px;
  color: #00dada;
  background: transparent;
  border: 1px solid #00dada;
  width: 120px;
  height: 40px;
`

const Cont = styled.div`
  ${tw`
      flex
      w-full
      justify-center
  `}
`

const RowContainer = styled.div`
  ${tw`
      flex
      flex-row
      justify-between
      align-sub
  `}
  width: 290px;
  align-items: baseline;
`

const NoteContainer = styled.p`
  margin-top: 5px;
  font-size: 14px;
  margin-left: 25px;
  margin-right: 15px;
  color: ${secondaryText};
  line-height: 1.2;
  margin-bottom: 15px;
`

export default function TaxesPage(){
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {loading, error, data, refetch} = useQuery(GET_TAXES_PREDICTION);

  const [current, setCurrent] = React.useState({
    currTotal: 0,
    currGains: 0
  })

  const [predicted, setPredicted] = React.useState({
    predictedTotal: 0,
  })

  const [yearsPIVA, setIVA] = React.useState();
  const [taxesPrediction, setTaxesPrediction] = React.useState();
  const [contributi, setContributi] = React.useState();
  const [gainPrediction, setGainPrediction] = React.useState();

  React.useEffect( () => {
    if(data){
      const {getTaxesSales} = data;
      setCurrent(prevActual => ({
        currTotal: getTaxesSales.salesYearly,
        currGains: getTaxesSales.gainsYearly
      }))
      setPredicted(prevActual => ({
        predictedTotal: getTaxesSales.totalSalesPrediction,
      }))
    }
  }, [data])

  function handleIva(event){
    const {value} = event.target;
    setIVA(value);
  }

  function calculateTaxes(){
    const {taxesToPay, contributi} = getForfettaryTaxes(current.currTotal, yearsPIVA);
    setTaxesPrediction(taxesToPay);
    setContributi(contributi);
  }

  const { state, dispatch} = useGlobalState();

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'taxData',
    });
  };

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };

  React.useEffect( ( ) => {
    if(state.dataUpdated.taxData){
      handleToggleDataUpdate();
      try{
        refetch();
      }
      catch(error){
        console.log(error);
      }
    }
  }, [])

  return(
    <div>
      <HeaderFixed pageTitle="TASSE" menuPage="TAXES"/>
      {!loading ? 
      <div>{current.currTotal && 
      <div>
        <div style={{
          backgroundColor: '#de6161', 
          color: 'white', 
          textAlign: 'center', 
          padding: '10px', 
          fontSize: '16px', 
          fontWeight: 'bold'
        }}>
          {t('TaxesPage.taxesWarning')}
        </div>
        <AnnualValues>
          <SectionTitle>
            Valori attuali
          </SectionTitle>
          <BoxContainer>
            <ValueBox>
              <span className="boxTitle">Vendite totali</span>
              <span className="boxValue"><b>{current.currTotal}</b><span className="euro">€</span></span>
            </ValueBox>
          </BoxContainer>
        </AnnualValues>
        <AnnualValues>
          <SectionTitle>
            Previsioni annuali
          </SectionTitle>
          <BoxContainer>
            <ValueBox>
              <span className="boxTitle">Vendite previste</span>
              <span className="boxValue"><b>{predicted.predictedTotal}</b><span className="euro">€</span></span>
            </ValueBox>
          </BoxContainer>
        </AnnualValues>
        <TaxesContainer>
          <SectionTitle>
            Indicazioni sulla tassazione
          </SectionTitle>
          <TaxesParagraph>
            Ci sono diversi regimi di tassazione per liberi professionisti, questo strumento di calcolo considera il regime forfettario. Per ulteriori informazioni, leggere la <a onClick={ () => {navigate('/guida')}}>guida.</a>
          </TaxesParagraph>
          <Cont>
            <RowContainer>
              <FormField type="number" width={mediumInput} name = "Anni P. IVA" inputName = "anniIVA" value = {yearsPIVA} handler={handleIva}></FormField>
              <CalculateButton onClick={calculateTaxes}>Calcola</CalculateButton>
            </RowContainer>
          </Cont>
          {taxesPrediction && <BoxContainer>
            <ValueBox>
              <span className="boxTitle">Tasse da pagare</span>
              <span className="boxValue"><b>{taxesPrediction}</b><span className="euro">€</span></span>
            </ValueBox>
            <ValueBox>
              <span className="boxTitle">Contributi da versare</span>
              <span className="boxValue"><b>{contributi}</b><span className="euro">€</span></span>
            </ValueBox>
            <ValueBox>
              <span className="boxTitle">Totale</span>
              <span className="boxValue"><b>{taxesPrediction + contributi}</b><span className="euro">€</span></span>
            </ValueBox>
            <ValueBox>
              <span className="boxTitle">Guadagno annuale previsto (netto)</span>
              <span className="boxValue"><b>{current.currGains - (taxesPrediction + contributi)}</b><span className="euro">€</span></span>
            </ValueBox>
          </BoxContainer>}
          {taxesPrediction && <NoteContainer>
            <b>Nota:</b> le tasse sono calcolate sulle vendite con carta.<br/>
            <b>Nota:</b> i contributi includono i 3000€ per la gestione commercianti.
          </NoteContainer>}
        </TaxesContainer>
      </div>}
      {!current.currTotal && 
        <NoItems>
          <img src={roundAlert} />
          <MessageContainer>
            <p>Per fornirti un'indicazione sulla tassazione dobbiamo avere dei dati sulla tua attività. Vuoi registrare i tuoi acquisti e vendite?</p>
          </MessageContainer>
          <MainButton active={true} text="Aggiungi" onClickFunction={() => {navigate('/nuovoOggetto');}}/>
        </NoItems>
      } </div> : 
    
      <LoadingAnimation />}
      <Navfixed />
    </div>
  )
}