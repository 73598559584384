import React, { useTransition } from "react"
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,    //x axis
  LinearScale,      //y axis
  PointElement,
  Filler,
  Tooltip
} from 'chart.js';
import { Chart, Line } from "react-chartjs-2";
import styled from 'styled-components'
import tw from "twin.macro";
import { useTranslation } from "react-i18next";

const ChartContainer = styled.div`
  ${tw`
      mx-4
      mb-8
      px-2
      py-4
      flex
      flex-col
      justify-center
  `}
  margin-top: 15px;
  border-radius: 15%;
`

const InfoContainer = styled.div`
  ${tw`
      text-white
      flex
      justify-between
      px-5
      items-center
      mb-3
  `}
`

const ChartTitle = styled.span`
  ${tw`
      text-white
      ml-1
      font-semibold
  `}
  color: rgba(180,201,225, 1);
  font-size: 22px;
`

const DataContainer = styled.div`
  ${tw`
      flex
      flex-col
  `}
  font-size: 14px;
  .color-subtitle{
    color: rgba(180,201,225, 1);
  }
`

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  Tooltip
)

export default function LineChart(props){
  const { data, title } = props;

  var sum = 0;
  var highestIndex = 0;
  var highestValue = data.datasets[0].data[0];
  for (let i = 0; i < data.datasets[0].data.length; i++){
    if (data.datasets[0].data[i] > highestValue){
      highestIndex = i;
      highestValue = data.datasets[0].data[i];
    }
    sum += data.datasets[0].data[i];
  }
  const options = {
    plugins: {
      legend: false,
      datalabels: {
        display: false,
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: 'rgba(180,201,225, 0.4)',
        },
        ticks: {
          color: 'rgba(180,201,225, 0.7)'
        }
      },
      y: {
        maxTicksLimit: 2,
        grid: {
          color: 'rgba(180,201,225, 0.4)',
        },
        ticks: {
          color: 'rgba(180,201,225, 0.7)'
        }
      }
    }
  }

  const { t } = useTranslation();

  return(
    <ChartContainer>
      <InfoContainer>
        <ChartTitle>{title}</ChartTitle>
        <DataContainer>
          <p><span className="color-subtitle">{t('LineChartComponent.total')} {title}:</span> {sum}€</p>
          <p><span className="color-subtitle">{t('LineChartComponent.peakMonth')}</span> {data.labels[highestIndex]} {data.datasets[0].data[highestIndex]}€</p>
        </DataContainer>
      </InfoContainer>
      <Line
            data = {data}
            options={options}
      />
    </ChartContainer>
  )
}