import React, { Fragment } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useQuery, gql, useMutation } from "@apollo/client";
import { formatDate, formatDateCorrectly, getIcon, longInput, mainBackground, mainFucsia, mainPurple, secondaryBackground, secondaryText } from "../const";
import search_icon from "../icons/search_icon_grey.png"
import search_icon_colored from "../icons/search_icon_colored.png"
import FormField from "./FormField";
import MainButton from "./MainButton";
import CDatePicker from "./CDatePicker";
import CorrectPopUp from "./CorrectPopUp";
import { FormRow } from "../styledElements";
import check from "../icons/check.png"
import { actionTypes, sellActionTypes, useGlobalState } from "../GlobalStateContext";
import ErrorPopUp from "./ErrorPopUp";
import ItemWarehouse from "./ItemWarehouse";
import back_arrow from "../icons/back_arrow.png"
import { useNavigate } from "react-router-dom";
import { Transition, Dialog} from "@headlessui/react";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";


const GET_ITEMS = gql`
query getUnsoldItems{
  getUnsoldItems{
    id
      name
      buyPrice
      imageUrl
      expectedSellPrice
      type
      buyDate
      tags{
        text
      }
      shoe{
        sizeEU
        gender
      }
      watch{
        modelNumber
        serialNumber
      }
      sneaker{
        SKU
        ShoeImage
        ShoeName
      }
  }
}
`

const SearchContainer = styled.div`
  ${tw`
      flex
      flex-col
      justify-center
  `}
  margin-top: 20px;
  input::placeholder{
    color: rgba(255,255,255,0.8);
  }

  .focused label .greyIcon {
    display: none;
  }
  
  .focused label .coloredIcon {
    display: inline;
  }
  
   label .coloredIcon {
    display: none;
  }
  
   label .greyIcon {
    display: inline;
  }
`

const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  label img {
    height 22px;
    width: 22px
  }

`


const SearchInput = styled.input`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 10px;
  padding-left: 55px;
  color: rgba(255,255,255,0.8);
  border-bottom: 2px solid rgba(255,255,255,0.8);
  background: transparent;

  &:focus {
    outline: none; /* Remove the default focus outline */
    background: transparent; /* Set the background to transparent */
    border-bottom: 2px solid aqua;
  }
`

const ItemsContainer = styled.div`
  ${tw`
      flex
      flex-col
      w-full
      px-6
  `}
`

const ButtonContainer = styled.div`
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
      opacity: 0.5;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  ${tw`
      flex
      justify-center
      w-full
  `}

  animation: slideIn 0.3s ease-in-out;
`

const SellButton = styled.button`
  border-radius: 10px;
  font-size: 18px;
  color: #00dada;
  background: transparent;
  border: 1px solid #00dada;
  width: 100px;
  height: 40px;
  margin-top: -10px;
  margin-bottom: 10px;
`


const SortContainer = styled.div`
  ${tw`
  `}
  margin-left: 20px;
  margin-bottom: 30px;
  margin-top: 10px;
`

const SortTitle = styled.div`
  color: white;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
`

const ItemField = styled.li`
  ${tw`
      flex
      flex-row
      w-full
      items-center
      py-2
  `}
  height: 65px;
  position: relative;
  ${props => !props.lastItem && 'border-bottom: 1px solid rgba(255,255,255,0.8);'}
`

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 10px;
  flex-shrink: 0;
  margin-right: 8%;
`

const SmallIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 5px;
`

const DescContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-start
  `}
  font-size: 0.9em;
`

const PriceContainer = styled.div`
  position: absolute;
  right: 25px;
`
const Arrow = styled.div`
  width: 30px;
  height: 30px;
  margin-top: 10px;
  z-index: 2;
`

const SellForm = styled.form`
  ${tw`
      flex
      flex-col
      items-center
      w-full
  `}
  padding-inline: 30px;

  .radio-row{
    padding: 0px 15%;
  }

  .radio-title{
    font-size: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-top: 20px;
    font-weight: 500;
    color: white;
  }
`

const FormContainer = styled.div`
  
  .text{
    color: rgba(255,255,255,0.8);
    font-weight: 600;
    font-size: 1.2em;
    margin-bottom: 20px;
    max-width: 100%;
  }

  .colored-name{
    color: ${mainFucsia};
    font-weight: 650;
  }

`

const FakeRadio = styled.div`
  ${tw`
      mr-2 
  `}
  width: 15px;
  height: 15px;
  background: transparent;
  border-width: 1px;
  border-color: ${mainPurple};
`


const RadioActive = styled.div`
  ${tw`
      flex
      justify-center
      items-center
      mr-2
  `}
  width: 15px;
  height: 15px;
  background: linear-gradient(to right, ${mainPurple}, ${mainFucsia});

  img {
    width: 10px;
    height: 10px;
  }
`

const GoalsTitle = styled.div`
font-size: 15px;
margin-bottom: 15px;
margin-top: -20px;
margin-left: 5px;
font-weight: 500;
color: white;
`

const GoalList = styled.div`
  display: flex;
  color: white;
  flex-wrap: wrap;
`

const SelectGoals = styled.div`
  ${tw`
      mt-2
  `}
`

const RadioContainer = styled.div`
  ${tw`
      flex
      flex-row
      items-center
      justify-center
      text-white
      mb-5
      w-full
  `}
  font-size: 16px;
`

const Goal = styled.div`
  ${tw`
      py-1
      px-3
  `}
  font-size: 14px;
  border-radius: 10px;
  background: transparent;
  ${props => props.active ? 'border: 1px solid #00dadacc;' : `border: 1px solid black;`}
  margin: 5px 5px;
  ${props => props.active && 'color: #00dada;'}
`

const InfoContainer = styled.div`
  ${tw`
      text-white
      py-4
      flex
      flex-col
      items-center
  `}
  width: 80%;
  position: fixed;
  top: 15%;
  border-radius: 15px;
  background: ${mainBackground};

  img{
    margin-top: 10px;
    width: 50%;
  }

  .iconImage{
    width: 50px;
    height: 50px;
  }

  .itemImage{
    max-width: 150px;
    max-height: 150px;
  }

  .itemName{
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 80%;
    word-wrap: break-word;
  }
`
const InfoRow = styled.div`
  ${tw`
      flex
      justify-between
  `}
  font-size: 14px;
  width: 70%;
  margin-bottom: 8px;
`

const InfoGroupTitle = styled.div`
  font-size: 16px;
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
`

const StyledButton = styled.button`
  ${tw`
      px-6
      py-2
      rounded-xl 
      text-center
      font-semibold
    `}
    position: relative;
    overflow: hidden;
    font-size: 16px;
    background: transparent;
    color: #00dada;
    border: 1px solid #00dada;
`;

export default function NewSaleForm(props){
  const { state, dispatch} = useGlobalState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'sellPageData',
    });
  };

  React.useEffect( ( ) => {
      if(state.dataUpdated.sellPageData){
        handleToggleDataUpdate();
        try{
          refetch();
        }
        catch(error){
          console.log(error);
        }
      }
    }, [])


  const handleClearItemToSell = () => {
    dispatch({
      type: sellActionTypes.RESET_ITEM_INFO,
      payload: {
        itemInfo: null,
      }
    })
  }

  const {itemToSell, goals} = props;
  const {loading, error, data, refetch} = useQuery(GET_ITEMS, {
    onError: (error) => {
      console.log(error);
    }
  });

  const [isInputFocused, setIsInputFocused] = React.useState(false);

  const SELLITEM = gql`
    mutation sellItem($itemId: Float!, $args: NewSaleArgs!){
      sellItem(itemId: $itemId, args: $args)
    }
  `

  function closeForm(){
    setShow(false);
  }

  const [sellItem, {loading: loadingSale, error: errorSale, data: dataSale}] = useMutation(SELLITEM, {
    onError: (e) => {
      setNotAdded(true);
      setTimeout( () => {
        setNotAdded(false);
      }, 1500);
    },
    onCompleted: (e) => {
      setAdded(true);
      setTimeout( () => {
        setAdded(false);
        setIndex(null);
        setSelectedItem(null);
        setBoolSeleceted(null);
        refetch();
      }, 1500);
    }
  });

  function handleItemClick(item){
    const {id} = item;
    if (showId == id)
      setShowId(null)
    else
      setShowId(id);
  }

  const [items, setItems] = React.useState([]);
  const [searchName, setSearchName] = React.useState('');
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(itemToSell? itemToSell : {});
  const [showId, setShowId] = React.useState(null);
  const [boolSelected, setBoolSeleceted] = React.useState(itemToSell? true : false);
  const [itemAdded, setAdded] = React.useState(false);
  const [itemNotAdded, setNotAdded] = React.useState(false);
  const [sellPrice, setSellPrice] = React.useState();
  const [saleCosts, setSaleCosts] = React.useState();
  const [dateSell, setSell] = React.useState(null);
  const [correctSellDate, setCorrectDate] = React.useState("");
  const [formCorrect, setForm] = React.useState(false);
  const [goalsUpdate, setGoals] = React.useState([]);

  React.useEffect(() => {
    if(state.itemToSell){
      setTimeout( () => {
        handleClearItemToSell();
      }, 1000);
    }
  }, [])

  React.useEffect( () => {
    if(data){
      setItems(data.getUnsoldItems);
      setFilteredItems(data.getUnsoldItems);
    }
  }, [data])

  React.useEffect( () => {
    if(dataSale){
      handleSetAllDataUpdated()
    }
  }, [dataSale])

  React.useEffect( () => {
    if (dateSell){
      const formattedDate = formatDateCorrectly(dateSell, i18n.language);
      setCorrectDate( formattedDate);
    }
  }, [dateSell])

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchName(term);

    const newFilteredItems = items
    .filter((item) => item.name.toLowerCase().includes(term.toLowerCase()))

    setFilteredItems(newFilteredItems);
  }

  function handlePrice(e){
    const {value} = e.target
    setSellPrice(value);
  }

  function handleSaleCosts(e){
    const {value} = e.target
    setSaleCosts(value);
  }

  function handleSelection(){
    setSearchName('');
    setSelectedItem(filteredItems[indexSelected]);
    setBoolSeleceted(true);
    closeForm();
  }
  
  React.useEffect( () => {
    setForm(dateSell !== null && sellPrice && saleCosts);
  }, [dateSell, sellPrice, saleCosts])
  
  async function handleSubmit(e){
    e.preventDefault();
    try{
      sellItem({
        variables: {
          itemId : parseFloat(selectedItem.id),
          args : {
            sellPrice: parseInt(sellPrice),
            sellDate: correctSellDate,
            saleCosts: parseInt(saleCosts),
            goalsUpdate: [...goalsUpdate]
          }
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  function changeGoal(event){
    const {id} = event.target;
    if (goalsUpdate.length > 0 && goalsUpdate.includes(id)){
      setGoals(prevGoal => prevGoal.filter(item => item !== id))
    }
    else{
      setGoals(prevGoal => [...prevGoal, id]);
    }
  }

  const [showInfo, setShow] = React.useState(false);
  const [itemForInfo, setItem] = React.useState(null);
  const [indexSelected, setIndex] = React.useState(null);
  
  function showInfoCard(item){
    setItem({...item});
    setShow(true);
    setIndex(filteredItems.indexOf(item));
  }

  function setTodaySell(e){
    e.preventDefault();
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    setSell(dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"))
  }

  return(
    <div>
      <SearchContainer>
      <SearchInputContainer className={isInputFocused ? 'focused' : ''}>
        <label htmlFor="searchInput">
          <img className="greyIcon" src={search_icon} />
          <img className="coloredIcon" src={search_icon_colored} />
        </label>
        <SearchInput
          type="text"
          id="searchInput"
          placeholder={t('NewItemPage.searchPlaceholder')}  // Using translation for placeholder
          value={searchName}
          onChange={handleSearch}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
      </SearchInputContainer>

      {!boolSelected && (
        <SortContainer>
          <SortTitle>{t('NewItemPage.selectItemToSell')}</SortTitle> {/* Translate this */}
        </SortContainer>
      )}

      {!boolSelected && (
        <ItemsContainer>
          {filteredItems.map((object, index) => (
            <div key={index}>
              <ItemWarehouse 
                item={object}
                sales={object.sellPrice}
                index={index}
                infoFunc={showInfoCard}
              />
            </div>
          ))}
        </ItemsContainer>
      )}
      </SearchContainer>
      
      {boolSelected && (
        <SellForm onSubmit={handleSubmit}>
          <FormContainer>
            <Arrow onClick={() => setBoolSeleceted(false)}>
              <img src={back_arrow} alt={t('NewItemPage.backButtonAlt')} />
            </Arrow>
            <p className="text">
              {t('NewItemPage.itemAddedText')} <span className="colored-name">{selectedItem.name}</span>?
            </p>
            <FormRow>
              <FormField
                width={longInput}
                name={t('NewItemPage.priceSellLabel')}
                pHolder="€"
                inputName="sellPrice"
                value={sellPrice}
                handler={handlePrice}
                fontDim="15px"
                type="number"
              />
            </FormRow>
            <FormRow>
              <FormField
                width={longInput}
                name={t('NewItemPage.saleCostsLabel')}
                pHolder="€"
                inputName="saleCosts"
                value={saleCosts}
                handler={handleSaleCosts}
                fontDim="15px"
                type="number"
              />
            </FormRow>
            <FormRow>
              <CDatePicker title={t('NewItemPage.saleDateLabel')} date={dateSell} setDate={setSell} />
              <StyledButton onClick={setTodaySell}>{t('NewItemPage.todayButtonText')}</StyledButton>
            </FormRow>
            <SelectGoals>
              <GoalsTitle>{t('NewItemPage.selectGoalsTitle')}</GoalsTitle>
              <GoalList>
                {goals.map((goal, index) => (
                  <Goal
                    id={goal.name}
                    key={index}
                    onClick={changeGoal}
                    active={goalsUpdate.includes(goal.name)}
                  >
                    {goal.name}
                  </Goal>
                ))}
              </GoalList>
            </SelectGoals>
            <MainButton active={formCorrect} text={t('NewItemPage.buttonText')} />
            {itemAdded && (
              <CorrectPopUp text={t('NewItemPage.itemAddedSuccess')} />
            )}
            {itemNotAdded && (
              <ErrorPopUp text={t('NewItemPage.itemNotAddedError')} />
            )}
          </FormContainer>
        </SellForm>
      )}
      <Transition.Root show={showInfo} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showInfo}
          onClose={() => closeForm()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {itemForInfo ? <InfoContainer>
                {!itemForInfo.imageUrl && <img className={itemForInfo.sneaker ? 'itemImage' : 'iconImage'} src={itemForInfo.sneaker && itemForInfo.sneaker.ShoeImage || getIcon(itemForInfo.type)} />}
                {itemForInfo.imageUrl && <img className={'itemImage'} src={itemForInfo.imageUrl} />}
                
                <span className="itemName">{itemForInfo.name}</span>
                
                <InfoGroupTitle>
                  {t('NewItemPage.infoGroupTitle')} {/* Translated "Informazioni generali" */}
                </InfoGroupTitle>

                <InfoRow>
                  <span className="itemBuyPrice">{t('NewItemPage.pricePurchaseLabel')}:</span>
                  <span>{itemForInfo.buyPrice}€</span>
                </InfoRow>

                <InfoRow>
                  <span className="itemExpectedPrice">{t('NewItemPage.expectedSellPriceLabel')}:</span>
                  <span>{itemForInfo.expectedSellPrice}€</span>
                </InfoRow>

                {itemForInfo.sellPrice && (
                  <InfoRow>
                    <span className="itemSellPrice">{t('NewItemPage.priceSellLabel')}:</span>
                    <span>{itemForInfo.sellPrice}€</span>
                  </InfoRow>
                )}

                {itemForInfo.sellPrice && (
                  <InfoRow>
                    <span className="itemSellPrice">{t('NewItemPage.saleCostsLabel')}:</span>
                    <span>{itemForInfo.saleCosts}€</span>
                  </InfoRow>
                )}

                {itemForInfo.sellPrice && (
                  <InfoRow>
                    <span className="itemSellPrice">{t('NewItemPage.gainLabel')}:</span>
                    <span>{itemForInfo.sellPrice - itemForInfo.buyPrice - itemForInfo.saleCosts}€</span>
                  </InfoRow>
                )}

                {itemForInfo.sellPrice && (
                  <InfoRow>
                    <span className="itemSellPrice">{t('NewItemPage.gainPercentLabel')}:</span>
                    <span>{Math.round((itemForInfo.sellPrice - itemForInfo.buyPrice - itemForInfo.saleCosts) / itemForInfo.sellPrice * 100)}%</span>
                  </InfoRow>
                )}

                <InfoRow>
                  <span className="itemBuyDate">{t('NewItemPage.buyDateLabel')}:</span>
                  <span>{formatDate(itemForInfo.buyDate)}</span>
                </InfoRow>

                {itemForInfo.sellDate && (
                  <InfoRow>
                    <span className="itemSellDate">{t('NewItemPage.sellDateLabel')}:</span>
                    <span>{formatDate(itemForInfo.sellDate)}</span>
                  </InfoRow>
                )}

                {itemForInfo.type === 'shoe' && (
                  <InfoGroupTitle>
                    {t('NewItemPage.shoeInfoTitle')} {/* Translated "Informazioni scarpa" */}
                  </InfoGroupTitle>
                )}

                {itemForInfo.type === 'shoe' && (
                  <InfoRow>
                    <span className="itemBuyDate">{t('NewItemPage.sizeLabel')}:</span>
                    <span>{itemForInfo.shoe.sizeEU}</span>
                  </InfoRow>
                )}

                {itemForInfo.type === 'watch' && (
                  <InfoGroupTitle>
                    {t('NewItemPage.watchInfoTitle')} {/* Translated "Informazioni orologio" */}
                  </InfoGroupTitle>
                )}

                {itemForInfo.type === 'watch' && (
                  <InfoRow>
                    <span className="itemBuyDate">{t('NewItemPage.modelNumberLabel')}:</span>
                    <span>{itemForInfo.watch.modelNumber}</span>
                  </InfoRow>
                )}

                {!itemForInfo.sellPrice && (
                  <MainButton active={true} text={t('NewItemPage.buttonText')} onClickFunction={handleSelection} />
                )}
              </InfoContainer> : <div></div>}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}