import { DatePicker, LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import React from "react";
import './CDatePicker.css';
import dayjs from "dayjs";
import  styled from "styled-components";
import InputAdornment from '@mui/material/InputAdornment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import tw from "twin.macro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb'
import { longInput, mediumInput } from "../const";

const PickerContainer = styled.div`
  ${tw`
      flex
      flex-col
      text-white
      font-medium
      items-start
      mb-6
  `}
  font-size: 15px;
  width: ${props => props.width};

  p{
    margin-bottom: 8px;
    margin-left: 5px;
  }
`;

const StyledMobileDatePicker = styled(MobileDatePicker)`
  ${tw`
      flex
      flex-row
      items-center
      w-full
  `}
  background: transparent;

  .MuiInputBase-root {
    height: 40px;
    border-bottom: 2px solid white;
    color: white;
    font-size: 16px;
    width: 100%;
  }

  .MuiInputBase-input {
    color: white;
    font-size: 16px;
  }
  
  .MuiSvgIcon-root {
    font-size: 20px;
    color: white;
  }

`;

export default function CDatePicker(props){
  const {date, setDate} = props
  const {title, width} = props;

  return(
    <PickerContainer width={width ? width : "200px"}>
      <p>{title}</p>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <StyledMobileDatePicker
          value={date}
          onChange={(newValue) => {console.log(newValue); setDate(newValue)}}
          inputFormat="DD-MM-YYYY"
          showDaysOutsideCurrentMonth
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              },
            },
          }}
          className="custom-date-picker"
        />
      </LocalizationProvider>
    </PickerContainer>
  )
}