import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './locales/en.json';
import itTranslations from './locales/it.json';

i18n
  .use(LanguageDetector)  // Language detection
  .use(initReactI18next)   // Bind i18n to React
  .init({
    resources: {
      en: {
        translation: enTranslations
      },
      it: {
        translation: itTranslations
      }
    },
    fallbackLng: 'en', // Fallback language when the detected language is not available
    lng: (() => {
      const storedLang = localStorage.getItem("language");  // Check local storage
      if (storedLang) return storedLang; 

      const browserLang = navigator.language || navigator.userLanguage; // Detect browser language
      const supportedLangs = ['en', 'it']; // Define supported languages

      return supportedLangs.includes(browserLang.split('-')[0]) ? browserLang.split('-')[0] : 'en';
    })(),    
    interpolation: {
      escapeValue: false // React already does escaping
    },
    react: {
      useSuspense: false // Avoid suspense for faster rendering (optional)
    },
    detection: {
      order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag'],
      caches: ['cookie', 'localStorage'], // Cache language preference
    }
  });

console.log("Detected Language: ", i18n.language);

export default i18n;