import { gql, useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components"
import tw from "twin.macro";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut, Pie } from "react-chartjs-2";
import HeaderFixed from "../components/HeaderFixed";
import DoughnutChart from "../components/DoughnutChart";
import { secondaryText } from "../const";
import Navfixed from "../components/Navfixed";
import MainButton from "../components/MainButton"
import roundAlert from "../icons/roundAlert.png";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../components/LoadingAnimation";
import { useGlobalState, actionTypes } from "../GlobalStateContext";
import { useTranslation } from 'react-i18next';



ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

const NoItems = styled.div`
  @keyframes appear{
    0%{
      transform: TranslateY(20px);
      opacity: 0;
    }
    100%{
      transform: TranslateY(0px);
      opacity: 1;
    }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }

  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      justify-center
      items-center
  `}

  margin-top: 30px;

  animation: appear 0.5s ease-in-out;

  span {
    font-size: 14px;
  }

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    animation: shake 0.5s ease-in-out 0.5s;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 90%;
  background: black;
  padding: 18px 10px;
  border-radius: 15px;
  text-align: center
`

const GeneralValues = styled.div`
  ${tw`
      w-full 
      mb-5
  `}
`

const AnnualValues = styled.div`
  ${tw`
      w-full 
  `}
  margin-top: 30px;
`

const BoxContainer = styled.div`
  ${tw`
    w-full 
    flex
    flex-col
    items-center
  `}
`

const SectionTitle = styled.p`
  ${tw`
      text-white
  `}
  font-size: 18px;
  margin-left: 25px;
  font-weight: 600;
`

const SectionParagraph = styled.p`
  font-size: 14px;
  margin-left: 25px;
  margin-right: 15px;
  color: ${secondaryText};
  line-height: 1.2;
`

const ValueBox = styled.div`
  width: 270px;
  height: 80px;
  border: 2px solid black;
  box-shadow: 0px 1px 1px #00b6b6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;

  .boxTitle{
    color: #00dada;
    font-size: 18px;
  }

  .boxValue{
    color: white;
    font-size: 24px;
  }

  margin-top: 20px;
  background: transparent;
  .boxPercent{
    color: #61DE70;
    font-size: 24px;
    font-weight: 500;
  }

  .euro{
    font-size: 0.9em;
    opacity: 0.9;
    margin-left: 2px;
  }
`

const GET_DATA = gql`
  query getDataPage{
    getDataPage{
      yearlyGain
      yearlySales
      monthlyGain
      monthlySales
      gainsTotalType{
        type
        total
      }
      salesTotalType{
        type
        total
      }
    }
  }
`

const GET_EXPENSES = gql`
  query getExpensesData{
    getExpensesData{
      yearlyExpenses
      monthlyExpenses
    }
  }
`


export default function DataPage(){
  const {loading, error, data, refetch} = useQuery(GET_DATA);
  const {loading: loadingExpenses, error: errorExpenses, data: dataExpenses, refetch: refetchExpenses} = useQuery(GET_EXPENSES);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [yearlyGain, setYearlyGain] = React.useState(0);
  const [yearlySales, setYearlySales] = React.useState(0);
  const [monthlyGain, setMonthlyGain] = React.useState(0);
  const [monthlySales, setMonthlySales] = React.useState(0);
  const [yearlyExpenses, setYearlyExpenses] = React.useState(0);
  const [monthlyExpenses, setMonthlyExpenses] = React.useState(0);
  const [gainsType, setGainsType] = React.useState({
    labels : [],
    data: []
  });
  const [salesType, setSalesType] = React.useState({
    labels : [],
    data: []
  });

  React.useEffect( () => {
    if(data){
      const {getDataPage} = data;
      setYearlyGain(getDataPage.yearlyGain);
      setYearlySales(getDataPage.yearlySales);
      setMonthlyGain(getDataPage.monthlyGain);
      setMonthlySales(getDataPage.monthlySales);
      setGainsType( prevPie => ({
        ...prevPie,
        labels: getDataPage.gainsTotalType.map(obj => obj.type),
        data: getDataPage.gainsTotalType.map(obj => obj.total),
      }));
      setSalesType( prevPie => ({
        ...prevPie,
        labels: getDataPage.salesTotalType.map(obj => obj.type),
        data: getDataPage.salesTotalType.map(obj => obj.total),
      }));
    }
  }, [data])

  React.useEffect( () => {
    if(dataExpenses){
      const {getExpensesData} = dataExpenses;
      setYearlyExpenses(getExpensesData.yearlyExpenses)
      setMonthlyExpenses(getExpensesData.monthlyExpenses)
    }
  }, [dataExpenses])

  const { state, dispatch} = useGlobalState();

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'dataData',
    });
  };

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };

  React.useEffect( ( ) => {
    if(state.dataUpdated.dataData){
      handleToggleDataUpdate();
      try{
        refetch();
      }
      catch(error){
        console.log(error);
      }
    }
  }, [])

  return (
    <div>
      <HeaderFixed 
        pageTitle={t('dataPage.pageTitle')} 
        menuPage="DATA"
      />
      {!loading ? (
        <div>
          {salesType.data.length > 0 && (
            <div>
              <GeneralValues>
                <SectionTitle>
                  {t('dataPage.totalValuesTitle')}
                </SectionTitle>
                <SectionParagraph>
                  {t('dataPage.totalValuesDescription')}
                </SectionParagraph>
                <DoughnutChart 
                  data={salesType} 
                  title={t('dataPage.totalSalesChartTitle')} 
                />
                <DoughnutChart 
                  data={gainsType} 
                  title={t('dataPage.totalGainsChartTitle')} 
                />
              </GeneralValues>
              <AnnualValues>
                <SectionTitle>
                  {t('dataPage.annualValuesTitle')}
                </SectionTitle>
                <BoxContainer>
                  <ValueBox>
                    <span className="boxTitle">{t('dataPage.annualSalesTitle')}</span>
                    <span className="boxValue"><b>{yearlySales}</b><span className="euro">€</span></span>
                  </ValueBox>
                  <ValueBox>
                    <span className="boxTitle">{t('dataPage.annualGainsTitle')}</span>
                    <span className="boxValue"><b>{yearlyGain}</b><span className="euro">€</span></span>
                  </ValueBox>
                  <ValueBox>
                    <span className="boxTitle">{t('dataPage.annualGainPercentageTitle')}</span>
                    <span className="boxPercent">{yearlyGain ? Math.floor((yearlyGain / yearlySales) * 100) : 0}%</span>
                  </ValueBox>
                  <ValueBox>
                    <span className="boxTitle">{t('dataPage.annualExpensesTitle')}</span>
                    <span className="boxValue"><b>{yearlyExpenses}</b><span className="euro">€</span></span>
                  </ValueBox>
                </BoxContainer>
              </AnnualValues>
              <AnnualValues>
                <SectionTitle>
                  {t('dataPage.monthlyValuesTitle')}
                </SectionTitle>
                <BoxContainer>
                  <ValueBox>
                    <span className="boxTitle">{t('dataPage.monthlySalesTitle')}</span>
                    <span className="boxValue"><b>{monthlySales}</b><span className="euro">€</span></span>
                  </ValueBox>
                  <ValueBox>
                    <span className="boxTitle">{t('dataPage.monthlyGainsTitle')}</span>
                    <span className="boxValue"><b>{monthlyGain}</b><span className="euro">€</span></span>
                  </ValueBox>
                  <ValueBox>
                    <span className="boxTitle">{t('dataPage.monthlyGainPercentageTitle')}</span>
                    <span className="boxPercent">{monthlyGain ? Math.floor((monthlyGain / monthlySales) * 100) : 0}%</span>
                  </ValueBox>
                  <ValueBox>
                    <span className="boxTitle">{t('dataPage.monthlyExpensesTitle')}</span>
                    <span className="boxValue"><b>{monthlyExpenses}</b><span className="euro">€</span></span>
                  </ValueBox>
                </BoxContainer>
              </AnnualValues>
            </div>
          )}
          {salesType.data.length === 0 && (
            <NoItems>
              <img src={roundAlert} />
              <MessageContainer>
                <p>{t('dataPage.noActivityMessage')}</p>
              </MessageContainer>
              <MainButton 
                active={true} 
                text={t('dataPage.addButtonText')} 
                onClickFunction={() => {navigate('/nuovoOggetto');}} 
              />
            </NoItems>        
          )}
        </div>
      ) : (
        <LoadingAnimation />
      )}
      <Navfixed />
    </div>
  );
}