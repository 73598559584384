import React from "react";
import HeaderFixed from "../components/HeaderFixed";
import Navfixed from "../components/Navfixed";
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import './css_files/GuidePage.css';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';  // Import i18n hook
import { secondaryText } from "../const";

const SectionParagraph = styled.p`
  margin-top: 20px;
  font-size: 14px;
  margin-left: 25px;
  margin-right: 15px;
  color: ${secondaryText};
  line-height: 1.2;
`;

export default function GuidePage() {
  const { t } = useTranslation();  // Initialize translation

  return (
    <div>
      <HeaderFixed pageTitle={t('guidePage.pageTitle')} menuPage="GUIDE" />
      <SectionParagraph>
        {t('guidePage.description')}
      </SectionParagraph>
      <div className="w-full px-4 pt-8">
        <div className="mx-auto w-full max-w-md rounded-2xl bg-transparent p-2">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>{t('guidePage.inventorySection')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6 customChevron`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  {t('guidePage.inventoryDescription')}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>{t('guidePage.goalsSection')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  {t('guidePage.goalsDescription')}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>{t('guidePage.dataSection')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  {t('guidePage.dataDescription')}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>{t('guidePage.documentsSection')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  {t('guidePage.documentsDescription')}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>{t('guidePage.accountingSection')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  {t('guidePage.accountingDescription')}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as={"div"} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="discButton text-white flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium focus:outline-none">
                  <span>{t('guidePage.taxesSection')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-6 w-6`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="panelText px-4 pt-4 pb-2 text-white">
                  {t('guidePage.taxesDescription')}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
      <Navfixed />
    </div>
  );
}