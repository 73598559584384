import React, { Fragment } from "react";
import TabItemList from "../components/TabItemList";
import { useNavigate } from "react-router-dom";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { formatDate, formatDateCorrectly, getIcon, longInput, mainBackground, mainFucsia, secondaryBackground, secondaryText, stringToArray } from "../const";
import styled from "styled-components";
import SmallDonut from "../components/SmallDonut";
import tw from "twin.macro";
import MainButton from "../components/MainButton";
import roundAlert from "../icons/roundAlert.png";
import Navfixed from "../components/Navfixed";
import { FormRow, OptionText, OptionWare, OptionWareIcon, OptionsContainer } from "../styledElements";
import CDatePicker from "../components/CDatePicker";
import TypesFiltering from "../components/TypesFiltering";
import './css_files/WalletPage.css'
import Slider from '@mui/material/Slider'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import filterIcon from '../icons/white_filt.png';
import LineChart from "../components/LineChart";
import PieChart from "../components/PieChart";
import { Dialog, RadioGroup, Tab, Transition } from "@headlessui/react"
import HeaderFixed from "../components/HeaderFixed";
import LoadingAnimation from "../components/LoadingAnimation";
import FormField from "../components/FormField";
import { actionTypes, sellActionTypes, useGlobalState } from "../GlobalStateContext"
import sell_icon from "../icons/sell_icon.png"
import collapse_icon from "../icons/collapse_icon.png"
import expand_icon from "../icons/expand_icon.png"
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SortContainer = styled.div`
  ${tw`
  `}
  margin-left: 20px;
  margin-bottom: 15px;
`

const SortTitle = styled.div`
  color: white;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;


  img{
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
`

const SortOption = styled.div`
  ${tw`
      py-1
      px-3
  `}
  font-size: 14px;
  border-radius: 10px;
  background: transparent;
  ${props => props.active ? 'border: 1px solid #00dadacc;' : 'border: 1px solid black;'}
  margin: 5px 5px;
  ${props => props.active && 'color: #00dada;'}
`

const Container = styled.div`
`

const InputContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-center
  `}
  width: 45%;

  span{
    color: white;
    font-size: 14px;
  }
`

const InputIconContainer = styled.div`
  ${tw`
      relative
      mt-2
      flex
      items-center
      w-full
  `}
`

const IconNumberContainer = styled.div`
  ${tw`
      absolute
      inset-y-0
      left-0
      flex
      items-center
      px-2
  `}
  background: black;
  border-bottom-left-radius: 10px;
  border-bottom: 2px solid white;
  border-top-left-radius: 10px;
`

const NumberInput = styled.input`
  ${tw`
      py-2
      pl-12
  `}
  width: 100%;
  background: transparent;
  color: white;
  border-bottom: 2px solid white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &:focus{
    outline: none;
  }
  `

const GET_ITEMS = gql`
query getItemsByUser{
  getItemsByUser{
    totalSales{
      label
      data
    }
    totalGain{
      label
      data
    }
    totalExpense{
      label
      data
    }
    noSell{
      id
      name
      buyDate
      buyPrice
      imageUrl
      expectedSellPrice
      sellPrice
      saleCosts
      type
      sellDate
      shoe{
        sizeEU
        gender
      }
      watch{
        modelNumber
        serialNumber
      }
      sneaker{
        SKU
        ShoeImage
        ShoeName
      }
      tags{
        text
      }
    }
    sold{
      id
      name
      buyDate
      buyPrice
      imageUrl
      expectedSellPrice
      sellPrice
      saleCosts
      type
      sellDate
      shoe{
        sizeEU
        gender
      }
      watch{
        modelNumber
        serialNumber
      }
      sneaker{
        SKU
        ShoeImage
        ShoeName
      }
      tags{
        text
      }
    }
    pieGains{
      type
      total
    }
    pieSales{
      type
      total
    }
    piePurchases{
      type
      total
    }
    acquistiTot
    venditeTot
    aspettativeTot
    acquistiVendite
    aspettativeVendite
    aspettativeGuadagno
    liquidity
  }
}
`

const InfoContainer = styled.div`
  ${tw`
      text-white
      py-4
      flex
      flex-col
      items-center
  `}
  width: 80%;
  position: fixed;
  top: 10%;
  border-radius: 15px;
  background: ${mainBackground};

  .generalIcon{
    margin-top: 10px;
    width: 50%;
  }

  .iconImage{
    width: 50px;
    height: 50px;
  }

  .itemImage{
    max-width: 150px;
    max-height: 150px;
  }

  .itemName{
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 80%;
    word-wrap: break-word;
  }
`
const InfoRow = styled.div`
  ${tw`
      flex
      justify-between
  `}
  font-size: 14px;
  width: 70%;
  margin-bottom: 8px;
`

const InfoGroupTitle = styled.div`
  font-size: 16px;
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
`

const PageTitle = styled.div`
  ${tw`
      text-white
      w-full
      flex
      justify-center
  `}
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 600;
`


const PageContainer = styled.div`
  ${tw`
      flex
      flex-col
  `}
`


const FilterTitle = styled.div`
  ${tw`
      text-white
      font-medium
  `}
  font-size: 15px;
  margin-bottom: 15px;
  margin-left: 5px;
`

const FilterSubTitle = styled.div`
  color: white;
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 15px;
  margin-left: 5px;
`

const CenterContainer = styled.div`
  ${tw`
      w-full
      flex
      justify-center
  `}
`

const StyledSlider = styled(Slider)`
  max-width: 270px;
  .MuiSlider-valueLabel{
    background-color: ${secondaryBackground};
    border-radius: 5px;
    padding: 5px 12px;
  }

  .MuiSlider-rail,
  .MuiSlider-track{
    height: 4px;
  }

  .MuiSlider-thumb{
    &.Mui-active,
    &.Mui-focusVisible{
      box-shadow: inherit;
    }

    width: 20px;
    height: 20px;
    background-color: white;
    border: 3px solid ${mainFucsia};
  }
`

const ChartsSection = styled.div`
  ${tw`
  `}
  margin-bottom: 25px;
`

const ChartSectionTitle = styled.span`
  ${tw`
      font-semibold
  `}
  margin-left: 25px;
  margin-bottom: 30px;
  font-size: 25px;
  color: rgba(255,255,255,0.8);
  position: relative;
`

const SectionParagraph = styled.p`
  font-size: 14px;
  margin-left: 25px;
  margin-right: 15px;
  color: ${secondaryText};
  line-height: 1.2;
`

const Filters = styled.div`
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  
  ${tw`
      flex
      flex-col
      px-3
      py-1
      justify-center
      mb-2
  `}
  background: ${mainBackground};

  ${props => props.visible && 'animation: slideIn 0.5s ease;'}
  ${props => props.closing && 'animation: slideOut 0.3s ease;'}
`

const DonutRow = styled.div`
  ${tw`
      flex
      flex-row
      justify-center
      items-center
      w-full
  `}
  padding-bottom: ${props => props.padding || '0px'};
  gap: 30px;
  overflow-x: hidden;
  overflow-y: hidden;
`
const ItemContainer = styled.div`
  padding-top: 20px;
  background-color: ${secondaryBackground};
  margin-bottom: 15px;
`

const TypeFilters = styled.div`
  ${tw`
      mt-2
      flex
      flex-col
  `}
`

const NoItems = styled.div`
  @keyframes appear{
    0%{
      transform: TranslateY(20px);
      opacity: 0;
    }
    100%{
      transform: TranslateY(0px);
      opacity: 1;
    }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }

  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      text-center
      justify-center
      items-center
  `}

  margin-top: 50%;

  animation: appear 0.5s ease-in-out;

  p {
    font-size: 14px;
  }

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    animation: shake 0.5s ease-in-out 0.5s;
  }
`

const MessageContainer = styled.div`
  margin-bottom: 10px;
  width: 90%;
  background: black;
  padding: 18px 10px;
  border-radius: 15px;
`

const FiltersContainer = styled.div`
  ${tw`
      w-full
      flex
      justify-center
      mb-2
  `}
`

const TypesContainer = styled.div`
  ${tw`
      flex
      flex-row
      w-full
      justify-around
  `}
`

const ShowFilters = styled.div`
  ${tw`
      flex
      items-center
      justify-center
      p-2
      flex-row
      mb-2
  `}
  font-size: 16px;
  color: white;
  width: 70%;
  border-radius: 12px;
  border: 2px solid black;
  ${props => !props.active ? 'box-shadow: 0px 1px 1px #00b6b6;' : ''};
  background: transparent;
  
  img{
    margin-left: 10px;
    width: 18px;
    height: 18px;
  }
`

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 10px;
  margin-bottom: 15px;
`

const InfoBox = styled.div`
  ${tw`
      flex
      flex-col
      items-center
      py-2
  `}
  margin: 0px 10% 15px 10%;
  border: 2px solid black;
  box-shadow: 0px 1px 1px #00b6b6;
  border-radius: 20px;
  color: white;

  .main{
    font-size: 18px;
  }

  .colorText{
    color: ${secondaryText};
  }

  .colorBlue{
    color: #00dada;
  }

  .secondary{
    color: #00dada;
    font-size: 16px;
  }

  .value{
    color: white;
    font-size: 18px;
    font-weight: 600;
  }
`
const Info =  styled.div`
  ${tw`
      flex
      flex-col
      items-center
      w-full
  `}
`

const SecondaryInfo = styled.div`
  ${tw`
      w-full
      px-2
      flex
      justify-around
  `}
`


export default function WalletPage(){
  const GET_FILTER_DATA = gql`
    query getFilteredData($filters: filterParams!){
      getFilteredData(filters: $filters){
        salesFiltered{
          label
          data
        }
        expenseFiltered{
          label
          data
        }
        gainFiltered{
          label
          data
        }
        sold{
          name
          buyPrice
          imageUrl
          buyDate
          sellPrice
          expectedSellPrice
          saleCosts
          type
          sellDate
          sneaker{
            SKU
            ShoeImage
            ShoeName
          }
          shoe{
            sizeEU
            gender
          }
          watch{
            modelNumber
            serialNumber
          }
        }
        noSell{
          name
          buyPrice
          imageUrl
          buyDate
          sellPrice
          expectedSellPrice
          saleCosts
          type
          sellDate
          sneaker{
            SKU
            ShoeImage
            ShoeName
          }
          shoe{
            sizeEU
            gender
          }
          watch{
            modelNumber
            serialNumber
          }
        }
        pieSales{
          type
          total
        }
        pieSales{
          type
          total
        }
        piePurchases{
          type
          total
        }
        pieGains{
          type
          total
        }
        salesCount
        purchasesCount
        acquistiTot
        venditeTot
        aspettativeTot
        acquistiVendite
        aspettativeVendite
        aspettativeGuadagno
        liquidity
      }
    }
  `;
  
  const navigate = useNavigate();

  function goToSale(){
    setTimeout( ( ) => {
      handleSetItemToSell()
      navigate('/nuovoOggetto')
    }, 200)
  }

  const {loading, error, data: dataWallet, refetch} = useQuery(GET_ITEMS, {
    onError: (error) => {
      console.log(error);
    }
  });
  const [getDataFilter, {loadingFilters, data: filteredData, errorFilters}] = useLazyQuery(GET_FILTER_DATA, {
    onError: (error) => {
      console.log(error);
    }
  });

  const [showFilters, setShowFilters] = React.useState(false); 

  const [noSellItems, setNoSell] = React.useState([]);
  const [soldItems, setSold] = React.useState([]);
  const [purchCount, setPurchCount] = React.useState(0);
  const [salesCount, setSalesCount] = React.useState(0);
  const [purchShow, setPurchShow] = React.useState(0);
  const [salesShow, setSalesShow] = React.useState(0);
  const [tags, setTags] = React.useState("");
  const [nameSearch, setNameSearch] = React.useState("");

  /*
      stati per grafici
  */
  const [sales, setSales] = React.useState({
    labels: [],
    datasets: [{
      labels: 'Sales',
      data: [],
      backgroundColor: 'rgb(0,255,255)',
      borderColor: 'rgba(0,255,255, 0.7)',
      tension: 0.4,
      fill: true,
      backgroundColor: (ctx) => {
        const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, 500); // Create a vertical gradient
        gradient.addColorStop(0, 'rgba(0, 255, 255, 0.7)'); // Start color (with opacity)
        gradient.addColorStop(0.5, 'rgba(0, 255, 255, 0)'); // Middle color with opacity 0
        gradient.addColorStop(1.0, 'rgba(32, 31, 63, 1)'); // End color (fully transparent)
        return gradient; // Set the gradient as the background color
      },
    }]
  });

  const [purchases, setPurchases] = React.useState({
    labels: [],
    datasets: [{
      labels: 'Purchases',
      data: [],
      backgroundColor: 'rgb(0,255,255)',
      borderColor: 'rgba(0,255,255, 0.7)',
      tension: 0.4,
      fill: true,
      backgroundColor: (ctx) => {
        const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, 500); // Create a vertical gradient
        gradient.addColorStop(0, 'rgba(0, 255, 255, 0.7)'); // Start color (with opacity)
        gradient.addColorStop(0.5, 'rgba(0, 255, 255, 0)'); // Middle color with opacity 0
        gradient.addColorStop(1.0, 'rgba(32, 31, 63, 1)'); // End color (fully transparent)
        return gradient; // Set the gradient as the background color
      },
    }]
  })

  const [gains, setGains] = React.useState({
    labels: ["01-01","01-02","01-03","01-04","01-05","01-06","01-07","01-08"],
    datasets: [{
      labels: 'Gains',
      data: [100, 200, 130, 250, 90, 310, 110, 170],
      backgroundColor: 'rgb(0,255,255)',
      borderColor: 'rgba(0,255,255, 0.7)',
      tension: 0.4,
      fill: true,
      backgroundColor: (ctx) => {
        const gradient = ctx.chart.ctx.createLinearGradient(0, 0, 0, 500); // Create a vertical gradient
        gradient.addColorStop(0, 'rgba(0, 255, 255, 0.7)'); // Start color (with opacity)
        gradient.addColorStop(0.5, 'rgba(0, 255, 255, 0)'); // Middle color with opacity 0
        gradient.addColorStop(1.0, 'rgba(32, 31, 63, 1)'); // End color (fully transparent)
        return gradient; // Set the gradient as the background color
      },
    }]
  });

  const [pieGains, setPieGains] = React.useState({
    labels: [],
    data: []
  });
  const [piePurchases, setPiePurchases] = React.useState({
    labels: [],
    data: []
  });
  const [pieSales, setPieSales] = React.useState({
    labels: [],
    data: []
  });
  const [filtersUsed, setFiltersUsed] = React.useState(false);
  const [purchDateFilter, setPurchDateFilter] = React.useState(false);
  const [sellDateFilter, setSellDateFilter] = React.useState(false);
  const [periodDateFilter, setPeriodDateFilter] = React.useState(false);


  /**
   * Stati per filtri
   */
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [sellDate, setSellDate] = React.useState(null);
  const [endSellDate, setEndSellDate] = React.useState(null);
  const [periodDate, setPeriodDate] = React.useState(null);
  const [endPeriodDate, setEndPeriodDate] = React.useState(null);
  const [filters, setFilters] = React.useState({
    startDate: null,
    endDate: `${year}-${month}-${day}`,
    startPrice: null,
    endPrice: null,
    sellDate: null,
    endSellDate: null,
    periodDate: null,
    endPeriodDate: null,
    startSellPrice: null,
    endSellPrice: null
  })
  const [typeSelections, setTypeSel] = React.useState({
    other: false,
    shoe: false,
    watch: false
  })
  const [priceRange, setPriceRange] = React.useState([0,3000]);
  const [donutValues, setDonuts] = React.useState({
    acquisti: 0,
    aspettativeAcquisti: 0,
    vendite: 0,
    aspettativeVendite: 0,
    guadagno: 0,
    aspettativeGuadagno: 0
  })

  const [onlySold, setOnlySold] = React.useState({
    acquisti: false,
    vendite: false,
    tutto: true
  })
  const [closing, setClosing] = React.useState(false);
  const [periodLiquidity, setLiquidity] = React.useState(0);
  const [sorting, setSorting] = React.useState('dateDesc')
  const [showSorting, setShowSorting] = React.useState(false);
  const [soldItemsToShow, setSoldToShow] = React.useState([])
  const [unsoldItemsToShow, setUnsoldToShow] = React.useState([])

  React.useEffect( () => {
    switch(sorting){
      case 'dateAsc':
        setSoldToShow(soldItems && soldItems.slice().sort((a, b) => new Date(a.sellDate) - new Date(b.sellDate)));
        setUnsoldToShow(noSellItems && noSellItems.slice().sort((a, b) => new Date(a.buyDate) - new Date(b.buyDate)));
        break;
      case 'dateDesc':
        setSoldToShow(soldItems && soldItems.slice().sort((a, b) => new Date(b.sellDate) - new Date(a.sellDate)));
        setUnsoldToShow(noSellItems && noSellItems.slice().sort((a, b) => new Date(b.buyDate) - new Date(a.buyDate)));
        break;
      case 'priceAsc':
        setSoldToShow(soldItems && soldItems.slice().sort((a, b) => a.sellPrice - b.sellPrice));
        setUnsoldToShow(noSellItems && noSellItems.slice().sort((a, b) => a.buyPrice - b.buyPrice));
        break;
      case 'priceDesc':
        setSoldToShow(soldItems && soldItems.slice().sort((a, b) => b.sellPrice - a.sellPrice));
        setUnsoldToShow(noSellItems && noSellItems.slice().sort((a, b) => b.buyPrice - a.buyPrice));
        break;
      case 'alphDesc':
        setSoldToShow(soldItems && soldItems.slice().sort((a, b) => b.name.localeCompare(a.name)));
        setUnsoldToShow(noSellItems && noSellItems.slice().sort((a, b) => b.name.localeCompare(a.name)));
        break;
      case 'alphAsc':
        setSoldToShow(soldItems && soldItems.slice().sort((a, b) => a.name.localeCompare(b.name)));
        setUnsoldToShow(noSellItems && noSellItems.slice().sort((a, b) => a.name.localeCompare(b.name)));
        break;
      default:
        break;
    }
  }, [sorting, soldItems, noSellItems]);

  React.useEffect( () => {
    if (dataWallet){
      setNoSell([...dataWallet.getItemsByUser.noSell]);
      setSold([...dataWallet.getItemsByUser.sold]);
      setPurchases( prevPur => ({
        ...prevPur,
        labels: dataWallet.getItemsByUser.totalExpense.map(obj => `${obj.label}`),
        datasets: [{
          ...prevPur.datasets[0],
          data: dataWallet.getItemsByUser.totalExpense.map(obj => obj.data)
        },],
      }));

      setSales( prevPur => ({
        ...prevPur,
        labels: dataWallet.getItemsByUser.totalSales.map(obj => `${obj.label}`),
        datasets: [{
          ...prevPur.datasets[0],
          data: dataWallet.getItemsByUser.totalSales.map(obj => obj.data)
        },],
      }));

      setGains( prevPur => ({
        ...prevPur,
        labels: dataWallet.getItemsByUser.totalGain.map(obj => `${obj.label}`),
        datasets: [{
          ...prevPur.datasets[0],
          data: dataWallet.getItemsByUser.totalGain.map(obj => obj.data)
        },],
      }));

      
      setPieGains( prevPie => ({
        ...prevPie,
        labels: dataWallet.getItemsByUser.pieGains.map(obj => obj.type),
        data: dataWallet.getItemsByUser.pieGains.map(obj => obj.total),
      }));

      setPieSales( prevPie => ({
        ...prevPie,
        labels: dataWallet.getItemsByUser.pieSales.map(obj => obj.type),
        data: dataWallet.getItemsByUser.pieSales.map(obj => obj.total),
      }));

      setPiePurchases( prevPie => ({
        ...prevPie,
        labels: dataWallet.getItemsByUser.piePurchases.map(obj => obj.type),
        data: dataWallet.getItemsByUser.piePurchases.map(obj => obj.total),
      }));
      setLiquidity(dataWallet.getItemsByUser.liquidity);
      setDonuts({
        acquisti: dataWallet.getItemsByUser.acquistiTot,
        aspettativeAcquisti: dataWallet.getItemsByUser.aspettativeTot,
        vendite: dataWallet.getItemsByUser.venditeTot,
        aspettativeVendite: dataWallet.getItemsByUser.aspettativeVendite,
        guadagno: dataWallet.getItemsByUser.venditeTot - dataWallet.getItemsByUser.acquistiVendite,
        aspettativeGuadagno: dataWallet.getItemsByUser.aspettativeGuadagno
      })
    }
  }, [dataWallet])

  React.useEffect( () => {
    if (startDate){
      const formattedDate = formatDateCorrectly(startDate, i18n.language);
      setFilters( prevFilters => {
        return({
          ...prevFilters,
          startDate: formattedDate
        })
      })
    }
  }, [startDate])


  React.useEffect( () => {
    if (endDate){
      const formattedDate = formatDateCorrectly(endDate, i18n.language);
      setFilters( prevFilters => {
        return({
          ...prevFilters,
          endDate: formattedDate
        })
      })
    }
  }, [endDate])

  React.useEffect( () => {
    if (sellDate){
      const formattedDate = formatDateCorrectly(sellDate, i18n.language);
      setFilters( prevFilters => {
        return({
          ...prevFilters,
          sellDate: formattedDate
        })
      })
    }
  }, [sellDate])


  React.useEffect( () => {
    if (endSellDate){
      const formattedDate = formatDateCorrectly(endSellDate, i18n.language);
      setFilters( prevFilters => {
        return({
          ...prevFilters,
          endSellDate: formattedDate
        })
      })
    }
  }, [endSellDate])

  React.useEffect( () => {
    if (periodDate){
      const formattedDate = formatDateCorrectly(periodDate, i18n.language);
      setFilters( prevFilters => {
        return({
          ...prevFilters,
          periodDate: formattedDate
        })
      })
    }
  }, [periodDate])


  React.useEffect( () => {
    if (endPeriodDate){
      const formattedDate = formatDateCorrectly(endPeriodDate, i18n.language);

      setFilters( prevFilters => {
        return({
          ...prevFilters,
          endPeriodDate: formattedDate
        })
      })
    }
  }, [endPeriodDate])


  React.useEffect( () => {  
    if(periodDateFilter){
      setPurchShow(purchCount)
      setSalesShow(salesCount)
    } else{
      setPurchShow(soldItems.length + noSellItems.length);
      setSalesShow(soldItems.length)
    }
  }, [soldItems, noSellItems])

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smooth scrolling, use 'auto' for instant scrolling
    });
  }

  function addItem(){
    navigate(`/nuovoOggetto`);
  }

  function changeShowFilters(){
    if (!showFilters){
      setShowFilters(!showFilters);
    }
    else{
      setClosing(true);      
      setTimeout( () => {
        setShowFilters(!showFilters);
        setClosing(false);
      }, 300)
    }
  }

  function handleNameSearch(event){
    const {value} = event.target;

    setNameSearch(value);
  }

  function manualUpdate(dataWallet){
    setNoSell([...dataWallet.getItemsByUser.noSell]);
    setSold([...dataWallet.getItemsByUser.sold]);
    setPurchases( prevPur => ({
      ...prevPur,
      labels: dataWallet.getItemsByUser.totalExpense.map(obj => `${obj.label}`),
      datasets: [{
        ...prevPur.datasets[0],
        data: dataWallet.getItemsByUser.totalExpense.map(obj => obj.data)
      },],
    }));

    setSales( prevPur => ({
      ...prevPur,
      labels: dataWallet.getItemsByUser.totalSales.map(obj => `${obj.label}`),
      datasets: [{
        ...prevPur.datasets[0],
        data: dataWallet.getItemsByUser.totalSales.map(obj => obj.data)
      },],
    }));

    setGains( prevPur => ({
      ...prevPur,
      labels: dataWallet.getItemsByUser.totalGain.map(obj => `${obj.label}`),
      datasets: [{
        ...prevPur.datasets[0],
        data: dataWallet.getItemsByUser.totalGain.map(obj => obj.data)
      },],
    }));

    
    setPieGains( prevPie => ({
      ...prevPie,
      labels: dataWallet.getItemsByUser.pieGains.map(obj => obj.type),
      data: dataWallet.getItemsByUser.pieGains.map(obj => obj.total),
    }));

    setPieSales( prevPie => ({
      ...prevPie,
      labels: dataWallet.getItemsByUser.pieSales.map(obj => obj.type),
      data: dataWallet.getItemsByUser.pieSales.map(obj => obj.total),
    }));

    setPiePurchases( prevPie => ({
      ...prevPie,
      labels: dataWallet.getItemsByUser.piePurchases.map(obj => obj.type),
      data: dataWallet.getItemsByUser.piePurchases.map(obj => obj.total),
    }));
    setLiquidity(dataWallet.getItemsByUser.liquidity);
    setDonuts({
      acquisti: dataWallet.getItemsByUser.acquistiTot,
      aspettativeAcquisti: dataWallet.getItemsByUser.aspettativeTot,
      vendite: dataWallet.getItemsByUser.venditeTot,
      aspettativeVendite: dataWallet.getItemsByUser.aspettativeVendite,
      guadagno: dataWallet.getItemsByUser.venditeTot - dataWallet.getItemsByUser.acquistiVendite,
      aspettativeGuadagno: dataWallet.getItemsByUser.aspettativeGuadagno
    })
  }

  function cleanFilters(closeBool){
    refetch()
      .then((result) => {
        manualUpdate(result.data);
      })
    setTags("");
    setNameSearch("");
    setFilters(
      { startDate: `${year - 30}-${month}-${day}`,
        endDate: `${year}-${month}-${day}`,
        startPrice: undefined,
        endPrice: undefined,
        sellDate: null,
        endSellDate: null,
        periodDate: null,
        endPeriodDate: null,
        startSellPrice: undefined,
        endSellPrice: undefined}
    )
    setTypeSel({
      other: false,
      shoe: false,
      watch: false
    })
    setStartDate(null);
    setEndDate(null);
    setEndSellDate(null);
    setSellDate(null);
    setEndPeriodDate(null);
    setPeriodDate(null);
    setPurchCount(0);
    setSalesCount(0);
    setPurchDateFilter(false);
    setSellDateFilter(false);
    setPeriodDateFilter(false);
    if (closeBool){
      closeFilters();
      scrollTop();
    }
  }

  function closeFilters(){
    setClosing(true);      
    setTimeout( () => {
      setShowFilters(!showFilters);
      setClosing(false);
    }, 300)
  }

  function useFilters(event){
    setClosing(true);      
    setTimeout( () => {
      setShowFilters(!showFilters);
      setClosing(false);
    }, 300)

    var itemTypes = [];

    if (typeSelections.other){
      itemTypes.push("other");
    }
    if (typeSelections.shoe){
      itemTypes.push("shoe");
    }
    if (typeSelections.watch){
      itemTypes.push("watch");
    }
    if (!typeSelections.other && !typeSelections.shoe && !typeSelections.watch){
      itemTypes = ["other", "shoe", "watch"];
    }

    const varFilters = {
      ...filters,
      itemTypes: [...itemTypes],
      tags: tags ? [...stringToArray(tags)] : null,
      name: nameSearch || null,
    }

    getDataFilter({
      variables:{
        filters: {...varFilters, includeSales: true}
      }
    });
    setFiltersUsed(true);
    if(filters.startDate) setPurchDateFilter(true);
    if(filters.sellDate) setSellDateFilter(true);
    if(filters.periodDate) setPeriodDateFilter(true);
    scrollTop();
  }

  React.useEffect( () => {
    if (filteredData && filteredData.getFilteredData){
      const {sold, noSell, salesFiltered, expenseFiltered, gainFiltered, pieSales, pieGains, piePurchases, salesCount, purchasesCount, acquistiTot, venditeTot, aspettativeTot, acquistiVendite, aspettativeVendite, aspettativeGuadagno, liquidity} = filteredData.getFilteredData;
      setNoSell(noSell);
      setSold(sold);
      setPurchCount(purchasesCount);
      setSalesCount(salesCount)
      setPurchases( prevPur => ({
        ...prevPur,
        labels: expenseFiltered.map(obj => `${obj.label}`),
        datasets: [{
          ...prevPur.datasets[0],
          data: expenseFiltered.map(obj => obj.data)
        },],
      }));

      setSales( prevPur => ({
        ...prevPur,
        labels: salesFiltered.map(obj => `${obj.label}`),
        datasets: [{
          ...prevPur.datasets[0],
          data: salesFiltered.map(obj => obj.data)
        },],
      }));

      setGains( prevPur => ({
        ...prevPur,
        labels: gainFiltered.map(obj => `${obj.label}`),
        datasets: [{
          ...prevPur.datasets[0],
          data: gainFiltered.map(obj => obj.data)
        },],
      }));

      
      setPieGains( prevPie => ({
        ...prevPie,
        labels: pieGains.map(obj => obj.type),
        data: pieGains.map(obj => obj.total),
      }));

      setPieSales( prevPie => ({
        ...prevPie,
        labels: pieSales.map(obj => obj.type),
        data: pieSales.map(obj => obj.total),
      }));

      setPiePurchases( prevPie => ({
        ...prevPie,
        labels: piePurchases.map(obj => obj.type),
        data: piePurchases.map(obj => obj.total),
      }));
      setLiquidity(liquidity);
      setDonuts({
        acquisti: acquistiTot,
        aspettativeAcquisti: aspettativeTot,
        vendite: venditeTot,
        aspettativeVendite: aspettativeVendite,
        guadagno: venditeTot - acquistiVendite,
        aspettativeGuadagno: aspettativeGuadagno
      })
    }
  }, [filteredData]);


  function changeType(event){
    setTypeSel(prevSel => {
      return({
        ...prevSel,
        [event.target.id]: !prevSel[event.target.id]
      })
    })
  }

  function handleTags(event){
    const {value} = event.target;

    setTags(value);
  }

  function changeSold(event){
    const {id} = event.target;
    if(id === "acquisti" && !onlySold[id]){
      setOnlySold(prevSold => {
        return({
          [id]: !prevSold[id],
          vendite: false,
          tutto: false
        })
      })
    }
    else if(id === "vendite" && !onlySold[id]){
      setOnlySold(prevSold => {
        return({
          [id]: !prevSold[id],
          acquisti: false,
          tutto: false
        })
      })
    }
    else if(id === "tutto" && !onlySold[id]){
      setOnlySold(prevSold => {
        return({
          [id]: !prevSold[id],
          acquisti: false,
          vendite: false
        })
      })
    }
    else{
      setOnlySold(prevSold => {
        return({
          [id]: !prevSold[id]
        })
      })
    }
  }

  function handlePriceChange(event, newValue){
    setPriceRange(newValue);
  }

  const { state, dispatch} = useGlobalState();

  const handleToggleDataUpdate = () => {
    dispatch({
      type: actionTypes.TOGGLE_DATA_UPDATE,
      payload: 'walletData',
    });
  };

  const handleSetAllDataUpdated = () => {
    dispatch({
      type: actionTypes.SET_ALL_DATA_UPDATED,
    });
  };

  React.useEffect( ( ) => {
    if(state.dataUpdated.walletData){
      handleToggleDataUpdate();
      try{
        refetch();
      }
      catch(error){
        console.log(error);
      }
    }
  }, [])

  const [showInfo, setShow] = React.useState(false);
  const [itemForInfo, setItem] = React.useState(null);

  function showInfoCard(item){
    setItem(item);
    setShow(true);
  }

  function closeForm(){
    setShow(false);
  }

  const handleSetItemToSell = () => {
    dispatch({
      type: sellActionTypes.SET_ITEM_TO_SELL,
      payload: {
        itemInfo: {...itemForInfo},
      }
    })
  }

  const { t } = useTranslation();


  return(
    <Container>
      <HeaderFixed pageTitle={t('accountingPage.pageTitle')} />
      {!loading ? <div>{(soldItems.length > 0 || noSellItems.length > 0) &&
        <PageContainer>
          <Tab.Group>
            <Tab.List className="navigationList flex">
              <Tab className={({ selected }) =>
                classNames(
                  'w-full text-md tabCustom br',
                  'focus:outline-none',
                  selected ? 'tabSel font-medium' : 'hover:text-white font-normal'
                )
              }>
                {t('accountingPage.filtersTab')}
              </Tab>
              <Tab className={({ selected }) =>
                classNames(
                  'w-full text-md tabCustom',
                  'focus:outline-none',
                  selected ? 'tabSel font-medium' : 'hover:text-white font-normal'
                )
              }>
                {t('accountingPage.chartsTab')}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <InfoBox>
                  <SecondaryInfo>
                    <Info>
                      <span className="secondary colorText">{t('accountingPage.purchasedItems')}</span>
                      <span className="secondary value">{purchShow}</span>
                    </Info>
                    <Info>
                      <span className="secondary colorText">{t('accountingPage.soldItems')}</span>
                      <span className="secondary value">{salesShow}</span>
                    </Info>
                  </SecondaryInfo>
                </InfoBox>
                <FiltersContainer>
                  <ShowFilters active={showFilters} onClick={changeShowFilters}>
                    {!showFilters ? t('accountingPage.showFilters') : t('accountingPage.hideFilters')}
                    <img src={filterIcon} />
                  </ShowFilters>
                </FiltersContainer>
                {showFilters &&
                  <Filters visible={showFilters} closing={closing}>
                    <FilterTitle>
                      {t('accountingPage.filterByName')}
                    </FilterTitle>
                    <CenterContainer>
                      <FormField width={longInput} name={t('accountingPage.filterByName')} inputName="nameSearch" value={nameSearch} handler={handleNameSearch} />
                    </CenterContainer>

                    <FilterTitle>
                      {t('accountingPage.filterByTag')}
                    </FilterTitle>
                    <FilterSubTitle>
                      {t('warehouse.filtersTagsPlaceholder')}
                    </FilterSubTitle>
                    <CenterContainer>
                      <FormField width={longInput} name="Tags" inputName="tags" value={tags} handler={handleTags} />
                    </CenterContainer>

                    <FilterTitle>
                      {t('accountingPage.filterByPurchaseDate')}
                    </FilterTitle>
                    <FormRow>
                      <CDatePicker date={startDate} setDate={setStartDate} width={"48%"} />
                      <CDatePicker date={endDate} setDate={setEndDate} width={"48%"} />
                    </FormRow>

                    <FilterTitle>
                      {t('accountingPage.filterBySellDate')}
                    </FilterTitle>
                    <FormRow>
                      <CDatePicker date={sellDate} setDate={setSellDate} width={"48%"} />
                      <CDatePicker date={endSellDate} setDate={setEndSellDate} width={"48%"} />
                    </FormRow>

                    <FilterTitle>
                      {t('accountingPage.filterByPeriod')}
                    </FilterTitle>
                    <FormRow>
                      <CDatePicker date={periodDate} setDate={setPeriodDate} width={"48%"} />
                      <CDatePicker date={endPeriodDate} setDate={setEndPeriodDate} width={"48%"} />
                    </FormRow>

                    <TypeFilters>
                      <FilterTitle>
                        {t('accountingPage.types')}
                      </FilterTitle>
                      <TypesContainer>
                        <TypesFiltering type={t("NewItemPage.generic")} state={typeSelections.other} onClickFunction={changeType} id="other" />
                        <TypesFiltering type={t("NewItemPage.shoe")} state={typeSelections.shoe} onClickFunction={changeType} id="shoe" />
                        <TypesFiltering type={t("NewItemPage.watch")} state={typeSelections.watch} onClickFunction={changeType} id="watch" />
                      </TypesContainer>
                    </TypeFilters>

                    <FilterTitle>
                      {t('accountingPage.pricePurchase')}
                    </FilterTitle>
                    <SliderContainer>
                      <InputContainer>
                        <span>Min</span>
                        <InputIconContainer>
                          <IconNumberContainer>
                            <EuroSymbolIcon sx={{ color: "rgba(255, 255,255, 0.7)" }} />
                          </IconNumberContainer>
                          <NumberInput
                            type="number"
                            value={filters.startPrice}
                            onChange={(e) => {
                              setFilters(prevFilters => ({ ...prevFilters, startPrice: parseInt(e.target.value) }));
                            }}
                          />
                        </InputIconContainer>
                      </InputContainer>
                      <InputContainer>
                        <span>Max</span>
                        <InputIconContainer>
                          <IconNumberContainer>
                            <EuroSymbolIcon sx={{ color: "rgba(255, 255,255, 0.7)" }} />
                          </IconNumberContainer>
                          <NumberInput
                            type="number"
                            value={filters.endPrice}
                            onChange={(e) => {
                              setFilters(prevFilters => ({ ...prevFilters, endPrice: parseInt(e.target.value) }));
                            }}
                          />
                        </InputIconContainer>
                      </InputContainer>
                    </SliderContainer>

                    <FilterTitle>
                      {t('accountingPage.priceSell')}
                    </FilterTitle>
                    <SliderContainer>
                      <InputContainer>
                        <span>Min</span>
                        <InputIconContainer>
                          <IconNumberContainer>
                            <EuroSymbolIcon sx={{ color: "rgba(255, 255,255, 0.7)" }} />
                          </IconNumberContainer>
                          <NumberInput
                            type="number"
                            value={filters.startSellPrice}
                            onChange={(e) => {
                              setFilters(prevFilters => ({ ...prevFilters, startSellPrice: parseInt(e.target.value) }));
                            }}
                          />
                        </InputIconContainer>
                      </InputContainer>
                      <InputContainer>
                        <span>Max</span>
                        <InputIconContainer>
                          <IconNumberContainer>
                            <EuroSymbolIcon sx={{ color: "rgba(255, 255,255, 0.7)" }} />
                          </IconNumberContainer>
                          <NumberInput
                            type="number"
                            value={filters.endSellPrice}
                            onChange={(e) => {
                              setFilters(prevFilters => ({ ...prevFilters, endSellPrice: parseInt(e.target.value) }));
                            }}
                          />
                        </InputIconContainer>
                      </InputContainer>
                    </SliderContainer>

                    <div className="flex justify-around px-2">
                      <MainButton active={true} text={t('accountingPage.applyFilters')} fontSize="12px" onClickFunction={useFilters} />
                      <MainButton active={true} text={t('accountingPage.clearFilters')} fontSize="12px" onClickFunction={() => { cleanFilters(true); }} />
                    </div>
                  </Filters>
                  }
                <SortContainer>
                  <SortTitle onClick={() => {setShowSorting(!showSorting)}}>
                    {t('accountingPage.sortBy')}
                    <img src={showSorting ? collapse_icon : expand_icon} alt="collapse or expand" />
                  </SortTitle>
                  {showSorting && <RadioGroup value={sorting} onChange={setSorting} className="radioWarehouse">
                    <RadioGroup.Option value="priceDesc">
                      {({ checked }) => (
                        <SortOption id="priceDesc" active={checked}>
                          {t('accountingPage.priceDesc')}
                        </SortOption>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="priceAsc">
                      {({ checked }) => (
                        <SortOption id="priceAsc" active={checked}>
                          {t('accountingPage.priceAsc')}
                        </SortOption>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="dateDesc">
                      {({ checked }) => (
                        <SortOption id="dateDesc" active={checked}>
                          {t('accountingPage.dateDesc')}
                        </SortOption>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="dateAsc">
                      {({ checked }) => (
                        <SortOption id="dateAsc" active={checked}>
                          {t('accountingPage.dateAsc')}
                        </SortOption>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="alphAsc">
                      {({ checked }) => (
                        <SortOption id="alphAsc" active={checked}>
                          {t('accountingPage.alphabeticalAsc')}
                        </SortOption>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="alphDesc">
                      {({ checked }) => (
                        <SortOption id="alphDesc" active={checked}>
                          {t('accountingPage.alphabeticalDesc')}
                        </SortOption>
                      )}
                    </RadioGroup.Option>
                  </RadioGroup>}
                </SortContainer>
                <ItemContainer>
                  <TabItemList noSellItems={unsoldItemsToShow} soldItems={soldItemsToShow} infoFunc={showInfoCard}/>
                </ItemContainer>
                <DonutRow>
                  <SmallDonut 
                    title={t('accountingPage.purchaseChartTitle')}
                    value={donutValues.acquisti}
                    subValue={donutValues.aspettativeAcquisti}
                    color1='#00ff00' 
                    color2="#00dada" 
                    id="#acquisti"
                    infoText={t('accountingPage.donutInfoTextPurchases')}
                    circleNum={1}
                  />
                  <SmallDonut 
                    title={t('accountingPage.sellChartTitle')}
                    value={donutValues.vendite}
                    subValue={donutValues.aspettativeVendite}
                    color1="#00ff00" 
                    color2="#00dada" 
                    id="#vendite"
                    infoText={t('accountingPage.donutInfoTextSales')}
                    circleNum={2}
                  />
                </DonutRow>
                <DonutRow padding="200px">
                  <SmallDonut 
                    title={t('accountingPage.gainsChartTitle')}
                    value={donutValues.guadagno}
                    subValue={donutValues.aspettativeGuadagno}
                    id="#guadagno" 
                    color1="#00ff00" 
                    color2="#00dada"
                    infoText={t('accountingPage.donutInfoTextGains')}
                    circleNum={3}
                  />
                  <SmallDonut 
                    title={t('accountingPage.liquidity')}
                    value={periodLiquidity}
                    subValue={periodLiquidity}
                    id="#guadagno" 
                    color1={periodLiquidity > 0 ? "#00ff00" : '#ff7f00'} 
                    color2={periodLiquidity > 0 ? "#00dada" : '#ff0000'}
                    infoText={t('accountingPage.donutInfoTextLiquidity')}
                    circleNum={4}
                  />
                </DonutRow>
                </Tab.Panel>
                <Tab.Panel>
                  <ChartsSection>
                    <ChartSectionTitle className="sectionTitle">
                      {t('accountingPage.lineChartsTitle')}
                    </ChartSectionTitle>
                    <SectionParagraph>
                      {t('accountingPage.lineChartsSubtitle')}
                    </SectionParagraph>
                    {purchases.datasets[0].data.length !== 0 &&
                      <LineChart data={purchases} title={t('accountingPage.purchaseChartTitle')} />
                    }
                    {sales.datasets[0].data.length !== 0 &&
                      <LineChart data={sales} title={t('accountingPage.sellChartTitle')} />
                    }
                    {gains.datasets[0].data.length !== 0 &&
                      <LineChart data={gains} title={t('accountingPage.gainsChartTitle')} />
                    }
                  </ChartsSection>
                  <ChartsSection>
                    <ChartSectionTitle className="sectionTitle">
                      {t('accountingPage.divisionChartsTitle')}
                    </ChartSectionTitle>
                    <SectionParagraph>
                      {t('accountingPage.divisionChartsSubtitle')}
                    </SectionParagraph>
                    {piePurchases.data.length !== 0 &&
                      <PieChart data={piePurchases.data} labels={piePurchases.labels} title={t('accountingPage.pieChartTitlePurchases')} total={donutValues.acquisti} />
                    }
                    {pieSales.data.length !== 0 &&
                      <PieChart data={pieSales.data} labels={pieSales.labels} title={t('accountingPage.pieChartTitleSales')} total={donutValues.vendite} />
                    }
                    {pieGains.data.length !== 0 &&
                      <PieChart data={pieGains.data} labels={pieGains.labels} title={t('accountingPage.pieChartTitleGains')} total={donutValues.guadagno} />
                    }
                  </ChartsSection>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>

          <Transition.Root show={showInfo} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" 
          open={showInfo}
          onClose={() => closeForm()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {itemForInfo ? (
                <InfoContainer>
                  {!itemForInfo.imageUrl && (
                    <img
                      className={itemForInfo.sneaker ? "itemImage" : "iconImage"}
                      src={itemForInfo.sneaker && itemForInfo.sneaker.ShoeImage || getIcon(itemForInfo.type)}
                    />
                  )}
                  {itemForInfo.imageUrl && <img className={"itemImage"} src={itemForInfo.imageUrl} />}
                  <span className="itemName">{itemForInfo.name}</span>
                  <InfoGroupTitle>{t('itemInfoCard.generalInfo')}</InfoGroupTitle>
                  <InfoRow>
                    <span className="itemBuyPrice">{t('itemInfoCard.purchasePrice')}</span>
                    <span>{itemForInfo.buyPrice}€</span>
                  </InfoRow>
                  <InfoRow>
                    <span className="itemExpectedPrice">{t('itemInfoCard.expectedSellPrice')}</span>
                    <span>{itemForInfo.expectedSellPrice}€</span>
                  </InfoRow>
                  {itemForInfo.sellPrice && (
                    <InfoRow>
                      <span className="itemSellPrice">{t('itemInfoCard.salePrice')}</span>
                      <span>{itemForInfo.sellPrice}€</span>
                    </InfoRow>
                  )}
                  {itemForInfo.sellPrice && (
                    <InfoRow>
                      <span className="itemSellPrice">{t('itemInfoCard.saleCosts')}</span>
                      <span>{itemForInfo.saleCosts}€</span>
                    </InfoRow>
                  )}
                  {itemForInfo.sellPrice && (
                    <InfoRow>
                      <span className="itemSellPrice">{t('itemInfoCard.profit')}</span>
                      <span>{itemForInfo.sellPrice - itemForInfo.buyPrice - itemForInfo.saleCosts}€</span>
                    </InfoRow>
                  )}
                  {itemForInfo.sellPrice && (
                    <InfoRow>
                      <span className="itemSellPrice">{t('itemInfoCard.profitPercentage')}</span>
                      <span>
                        {Math.round(
                          (itemForInfo.sellPrice - itemForInfo.buyPrice - itemForInfo.saleCosts) /
                            itemForInfo.sellPrice *
                            100
                        )}
                        %
                      </span>
                    </InfoRow>
                  )}
                  <InfoRow>
                    <span className="itemBuyDate">{t('itemInfoCard.purchaseDate')}</span>
                    <span>{formatDate(itemForInfo.buyDate)}</span>
                  </InfoRow>
                  {itemForInfo.sellDate && (
                    <InfoRow>
                      <span className="itemSellDate">{t('itemInfoCard.saleDate')}</span>
                      <span>{formatDate(itemForInfo.sellDate)}</span>
                    </InfoRow>
                  )}
                  {itemForInfo.type == "shoe" && <InfoGroupTitle>{t('itemInfoCard.shoeInfo')}</InfoGroupTitle>}
                  {itemForInfo.type == "shoe" && (
                    <InfoRow>
                      <span className="itemBuyDate">{t('itemInfoCard.shoeSize')}</span>
                      <span>{itemForInfo.shoe.sizeEU} {itemForInfo.shoe.gender}</span>
                    </InfoRow>
                  )}
                  {itemForInfo.type == "watch" && <InfoGroupTitle>{t('itemInfoCard.watchInfo')}</InfoGroupTitle>}
                  {itemForInfo.type == "watch" && (
                    <InfoRow>
                      <span className="itemBuyDate">{t('itemInfoCard.modelNumber')}</span>
                      <span>{itemForInfo.watch.modelNumber}</span>
                    </InfoRow>
                  )}
                </InfoContainer>
              ) : (
                <div></div>
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
        </PageContainer>
      }
      {soldItems.length === 0 && noSellItems.length === 0 && !filtersUsed && (
        <NoItems>
          <img src={roundAlert} />
          <MessageContainer>
            <p>{t('accountingPage.noItemsRegistered')}</p>
          </MessageContainer>
          <MainButton active={true} text={t('accountingPage.addItemButton')} onClickFunction={addItem} />
        </NoItems>
      )}

      {soldItems.length === 0 && noSellItems.length === 0 && filtersUsed && (
        <NoItems>
          <img src={roundAlert} />
          <MessageContainer>
            <p>{t('accountingPage.noItemsMatchingFilters')}</p>
          </MessageContainer>
          <MainButton active={true} text={t('accountingPage.clearFiltersButton')} onClickFunction={() => { cleanFilters(false) }} />
        </NoItems>
      )}
      )</div>:
      
      (
        <LoadingAnimation />
      )
      }
      <Navfixed currentPage={"wallet"}/>
    </Container>
  )
}
/**
 * <TypeFilters>
                        <FilterTitle>
                          Acquisti o vendite
                        </FilterTitle>
                        <TypesContainer>
                          <TypesFiltering type="Tutti" state={onlySold.tutto} onClickFunction={changeSold} id="tutto"/>
                          <TypesFiltering type="Acquisti" state={onlySold.acquisti} onClickFunction={changeSold} id="acquisti"/>
                          <TypesFiltering type="Vendite" state={onlySold.vendite} onClickFunction={changeSold} id="vendite"/>
                        </TypesContainer>
                      </TypeFilters>
 */