import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { Fragment } from "react";
import styled from "styled-components"
import tw from "twin.macro";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import HeaderFixed from "../components/HeaderFixed";
import italianDate, { allDate, calculateExpiryDate, expensesPeriodsByLanguage, formatDate, formatDateCorrectly, getExpensePeriodEnglish, getPeriodFormat, isSingleExpense, longInput, mainBackground, secondaryText, stringToArray, timeExpensePeriods } from "../const";
import Navfixed from "../components/Navfixed";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../components/LoadingAnimation";
import ExpensePageChart from "../components/ExpensePageChart";
import ExpensesShower from "../components/ExpensesShower";
import search_icon from "../icons/search_icon.png"
import filter_icon from "../icons/white_filter.png"
import { Dialog, Transition } from "@headlessui/react";
import FormField from "../components/FormField";
import TypesFiltering from "../components/TypesFiltering";
import CDatePicker from "../components/CDatePicker";
import { FormRow, OptionsContainer, OptionWareIcon, OptionText, OptionWare } from "../styledElements";
import dayjs from "dayjs";
import CSelector from "../components/CSelector";
import MainButton from "../components/MainButton";
import ErrorPopUp from "../components/ErrorPopUp";
import CorrectPopUp from "../components/CorrectPopUp";
import './css_files/ExpensePage.css'
import delete_red from "../icons/delete_red.png"
import edit_icon from "../icons/edit_icon.png"
import unsubscribe_icon from "../icons/unsubscribe_icon.png"
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";
import { actionTypes, useGlobalState } from "../GlobalStateContext";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

const TypeFilters = styled.div`
  ${tw`
      flex
      flex-col
      w-full
  `}
`

const ChoiceContainer = styled.div`
${tw`
flex
flex-col
items-center
fixed
`}
border: 1px solid #888888;
top: 40%;
border-radius: 15px;
width: 300px;

background-color: #242424;

.textDiv{
color: ${secondaryText};
text-align: center;
font-size: 16px;
padding: 15px 15px;
}

.buttonDiv{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

.choiceButton{
width: 50%;
color: #00dada; 
font-size: 18px;
font-weight: 600;
padding: 5px 10px;
border: 1px solid #888888;
background-color: ${mainBackground};
}

.left{
border-bottom-left-radius: 15px;

}
.right{
border-bottom-right-radius: 15px;

}
`

const SpaceDiv = styled.div`
  ${tw`
      w-full
      mt-3
  `}
`

const FilterTitle = styled.div`
  ${tw`
      text-white
      font-medium
  `}
  font-size: 15px;
  margin-bottom: 10px;
  margin-left: 5px;
  text-align: left
`
const TypesContainer = styled.div`
  ${tw`
      flex
      flex-row
      w-full
      justify-around
  `}
`

const PeriodContainer = styled.div`
  ${tw`
      w-full
      flex
      flex-col
      items-center
      mt-2
  `}
`
const ButtonsContainer = styled.div`
  ${tw`
      flex
      justify-around
      items-center
  `}
  color: #ffffffCC;
  width: 80%;
  font-size: 16px;
`
const PeriodButton = styled.button`
  ${tw`
    flex
  `}
  position: relative;
  padding: 5px 10px; /* Optional padding for better underline positioning */
  cursor: pointer; /* Indicate hover/click interaction */

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px; /* Adjust for padding if used */
    left: 0;
    background-color: #00dada;
    transition: width 0.3s ease-in-out;
  }

  ${props => props.selected && `
    color: #00dada;
    font-weight: 600;
    &::after{
      width: 100%; /* Animate to full width on hover or selection */
    }
  `}

  /* Box shadow for click invitation (optional) */
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  transition: box-shadow 0.15s ease-in-out; /* Subtle box-shadow animation */

  /* Optional adjustments for hover/selection: */
  &:hover,
  &.selected {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3); /* Increased depth on hover/selection */
  }
`;

const PeriodDate = styled.div`
  ${tw`
      text-white
      mt-2
  `}
  font-size: 16px;
`

const SearchContainer = styled.div`
  ${tw`
      mt-2
      flex
      justify-around
  `}

`
const SearchInput = styled.input`
  display: flex;
  align-items: center;
  height: 40px;
  width: 280px;
  border-radius: 15px;
  padding-left: 45px;
  color: rgba(255,255,255,0.8);
  border-left: 1px solid rgba(255,255,255,0.8);
  border-bottom: 1px solid rgba(255,255,255,0.8);
  border-right: 1px solid rgba(255,255,255,0.8);
  background: transparent;

  &:focus {
    outline: none; /* Remove the default focus outline */
    background: transparent; /* Set the background to transparent */
    border-left: 2px solid aqua;
    border-bottom: 2px solid aqua;
    border-right: 2px solid aqua;
  }
`

const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;

  label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  label img {
    height 22px;
    width: 22px
  }
`

const ContainerSearch = styled.div`
  ${tw`
      flex
      items-center
      justify-center
      w-full
  `}
`

const DivContainer = styled.div`
  ${tw`
      flex
      items-center
      justify-between
  `}

  width: 90%;
`
const FilterIcon = styled.img`
  width: 35px;
  height: 35px;
  padding: 5px;
  margin-top: 5px;
`

const FilterSubTitle = styled.div`
  color: white;
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 15px;
  margin-left: 5px;
`

const CenterContainer = styled.div`
  ${tw`
      w-full
      flex
      justify-center
  `}
`



const GET_EXPENSES = gql`
  query getExpensesHome{
    getExpensesHome{
      expenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      weeklyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      monthlyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      yearlyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      weekTotal
      monthTotal
      yearTotal
      total
    }
  }
`

const FormContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-start
      pt-7
      px-5
      pb-10
  `}
  border-radius: 20px;
  background: #242424;
  color: white;
  position: fixed;
  top: 5%;
`

const OptionContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-start
      py-7
      px-6
  `}
  width: 90%;
  border-radius: 20px;
  background: #242424;
  color: white;
  position: fixed;
  top: 25%;
`

const InfoContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-start
      w-full
      px-6
      my-2
  `}
`
const InfoRow = styled.div`
  ${tw`
      text-white
      flex
      w-full
      justify-between
      my-1
  `}

  font-size: 16px;

  .info{
    font-weight: 500;
  }
`

const FormTitle = styled.div`
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  width: 100%;
`

const OptionTitle = styled.div`
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  width: 100%;
`

const StyledButton = styled.button`
  ${tw`
      px-6
      py-2
      rounded-xl 
      text-center
      font-semibold
    `}
    position: relative;
    overflow: hidden;
    font-size: 16px;
    background: transparent;
    color: #00dada;
    border: 1px solid #00dada;
`;

const CREATE_EXPENSE = gql`
  mutation createExpense($expenseArgs: NewExpenseArgs!){
    createExpense(expenseArgs: $expenseArgs){
      expenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      weeklyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      monthlyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      yearlyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      weekTotal
      monthTotal
      yearTotal
      total
    }
  }
`

const FILTER_EXPENSE = gql`
  query filterExpenses($filters: filterExpense!){
    filterExpenses(filters: $filters){
      expenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      weeklyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      monthlyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      yearlyExpenses{
        period
        type
        id
        amount
        date
        title
        category
      }
      weekTotal
      monthTotal
      yearTotal
      total
    }
  }
`

const DELETE_EXPENSE = gql`
  mutation deleteExpense($expenseId: Float!){
    deleteExpense(expenseId: $expenseId)
  }
`

const DELETE_SUBSCRIPTION = gql`
  mutation deleteSubscription($expenseName: String!){
    deleteSubscription(expenseName: $expenseName)
  }
`

const CHANGE_EXPENSE = gql`
  mutation changeExpense($expenseInfo: ChangeExpenseArgs!, $expenseId: Float!){
    changeExpense(expenseInfo: $expenseInfo, expenseId: $expenseId)
  }
`

const Filters = styled.div`
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  
  ${tw`
      flex
      flex-col
      px-3
      py-1
      justify-center
      my-4
  `}
  background: ${mainBackground};

  ${props => props.visible && 'animation: slideIn 0.5s ease;'}
  ${props => props.closing && 'animation: slideOut 0.3s ease;'}
`


export default function ExpensesPage(){
  const {loading: loadingExpenses, data: dataExpenses, refetch: refetchExpenses} = useQuery(GET_EXPENSES);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getExpenseFilter, {loadingFilters, data: filteredData, errorFilters}] = useLazyQuery(FILTER_EXPENSE, {
    onError: (error) => {
      console.log(error);
    }
  });

  const [total, setTotal] = React.useState();
  const [activeExpenses, setActiveExpenses] = React.useState([]);
  const [expenseValues, setExpenseValues] = React.useState({
    weekTotal: 0,
    monthTotal: 0,
    yearTotal: 0,
    total: 0
  })
  const [activePeriod, setActivePeriod] = React.useState("");
  const [weekExpenses, setWeekExpenses] = React.useState([]);
  const [monthExpenses, setMonthExpenses] = React.useState([]);
  const [yearExpenses, setYearExpenses] = React.useState([]);
  const [totalExpenses, setTotalExpenses] = React.useState([]);
  const [expensesToShow, setExpensesToShow] = React.useState([]);

  const [searchName, setSearchName] = React.useState("");

  const { state, dispatch} = useGlobalState();
  
    const handleToggleDataUpdate = () => {
      dispatch({
        type: actionTypes.TOGGLE_DATA_UPDATE,
        payload: 'expenseData',
      });
    };
  
    const handleSetAllDataUpdated = () => {
      dispatch({
        type: actionTypes.SET_ALL_DATA_UPDATED,
      });
    };
  
    React.useEffect( ( ) => {
      if(state.dataUpdated.expenseData){
        handleToggleDataUpdate();
        try{
          refetchExpenses();
        }
        catch(error){
          console.log(error);
        }
      }
    }, [])

  React.useEffect( () => {
    if(dataExpenses){
      const {getExpensesHome} = dataExpenses;
      setExpenseValues({
        weekTotal: getExpensesHome.weekTotal,
        monthTotal: getExpensesHome.monthTotal,
        yearTotal: getExpensesHome.yearTotal,
        total: getExpensesHome.total
      });
      setTotal(getExpensesHome.weekTotal);
      setActiveExpenses(getExpensesHome.weeklyExpenses)
      setActivePeriod("week");
      setWeekExpenses(getExpensesHome.weeklyExpenses);
      setMonthExpenses(getExpensesHome.monthlyExpenses);
      setYearExpenses(getExpensesHome.yearlyExpenses);
      setTotalExpenses(getExpensesHome.expenses)
    }
  }, [dataExpenses])

  React.useEffect( () => {
    if(filteredData){
      const {filterExpenses} = filteredData;
      setExpenseValues({
        weekTotal: filterExpenses.weekTotal,
        monthTotal: filterExpenses.monthTotal,
        yearTotal: filterExpenses.yearTotal,
        total: filterExpenses.total
      });
      setWeekExpenses(filterExpenses.weeklyExpenses);
      setMonthExpenses(filterExpenses.monthlyExpenses);
      setYearExpenses(filterExpenses.yearlyExpenses);
      setTotalExpenses(filterExpenses.expenses);
      if(activePeriod === "week"){
        setActiveExpenses(filterExpenses.weeklyExpenses);
        setTotal(filterExpenses.weekTotal)
      } else if(activePeriod === "month"){
        setActiveExpenses(filterExpenses.monthlyExpenses);
        setTotal(filterExpenses.monthTotal)
      } else if(activePeriod === "year"){
        setActiveExpenses(filterExpenses.yearlyExpenses);
        setTotal(filterExpenses.yearTotal)
      } else if(activePeriod === "all"){
        setActiveExpenses(filterExpenses.expenses);
        setTotal(filterExpenses.total)
      }
    }
  }, [filteredData])

  const [dataChart, setDataChart] = React.useState({
    labels: [],
    data: []
  })

  const assignActiveExpenses = () => {
    if(activePeriod === "week"){
      setActiveExpenses(weekExpenses);
      setTotal(expenseValues.weekTotal)
    } else if(activePeriod === "month"){
      setActiveExpenses(monthExpenses);
      setTotal(expenseValues.monthTotal)
    } else if(activePeriod === "year"){
      setActiveExpenses(yearExpenses);
      setTotal(expenseValues.yearTotal)
    } else if(activePeriod === "all"){
      setActiveExpenses(totalExpenses);
      setTotal(expenseValues.total)
    }
  }

  React.useEffect(() => {
    if(activePeriod === "week"){
      setActiveExpenses(weekExpenses);
      setTotal(expenseValues.weekTotal)
    } else if(activePeriod === "month"){
      setActiveExpenses(monthExpenses);
      setTotal(expenseValues.monthTotal)
    } else if(activePeriod === "year"){
      setActiveExpenses(yearExpenses);
      setTotal(expenseValues.yearTotal)
    } else if(activePeriod === "all"){
      setActiveExpenses(totalExpenses);
      setTotal(expenseValues.total)
    }
  }, [activePeriod]);

  React.useEffect(() => {
    if (activeExpenses) {
      const groupedExpenses = activeExpenses.reduce((acc, expense) => {
        const category = expense.category;
        acc[category] = (acc[category] || 0) + expense.amount;
        return acc;
      }, {});
  
      const labels = Object.keys(groupedExpenses);
      const data = Object.values(groupedExpenses);
  
      setDataChart({ labels, data });

      setExpensesToShow(
        [...activeExpenses.filter((item) => item.title.toLowerCase().includes(searchName.toLowerCase()))]
      )
    } else{
      setExpensesToShow([]);
    }
  }, [activeExpenses]);

  React.useEffect(() => {
    setExpensesToShow(
      [...activeExpenses.filter((item) => item.title.toLowerCase().includes(searchName.toLowerCase()))]
    )
  }, [searchName]);




  function handleSearch(event){
    const {value} = event.target;

    setSearchName(value);
  }


  const [showForm, setShow] = React.useState(false);

  function closeForm(){
    setShow(false);
  }

  const [expenseInfo, setExpenseInfo] = React.useState({
    title: "",
    amount: null,
    expenseDate: null,
    category: ""
  })

  function handleInput(event){
    const {name, value} = event.target;
    setExpenseInfo( prevExp => {
      return(
        {
          ...prevExp,
          [name]: value
        }
      )
    });
  }

  function handleChange(event){
    const {name, value} = event.target;
    setExpenseToChange( prevExp => {
      return(
        {
          ...prevExp,
          [name]: value
        }
      )
    });
  }

  const [typeSelections, setTypeSelections] = React.useState({
    singola: true,
    periodica: false
  })

  const [timeSelections, setTimeSelections] = React.useState(expensesPeriodsByLanguage.en[0])
  const [timeOptions, setTimeOptions] = React.useState(expensesPeriodsByLanguage.en)

  React.useEffect(() => {
    if(i18n.language == 'it'){
      setTimeOptions(expensesPeriodsByLanguage.it);
      setTimeSelections(expensesPeriodsByLanguage.it[0]);
    }
  }, []);

  const [timeChangeSelections, setTimeSelectionsChange] = React.useState(null)

  function changeType(event){
    const {id} = event.target;
    if(isSingleExpense(id) && !typeSelections.singola){
      setTypeSelections(prevSel => {
        return({
          singola: true,
          periodica: false 
        })
      })
    } else if(!isSingleExpense(id) && !typeSelections.periodica){
      setTypeSelections(prevSel => {
        return({
          periodica: true,
          singola: false 
        })
      })
    }
  }

  const [date, setDate] = React.useState(null)

  React.useEffect( () => {
    if (date){
      const formattedDate = formatDateCorrectly(date, i18n.language);
      setExpenseInfo( prevExp => {
        return(
          {
            ...prevExp,
            expenseDate: formattedDate
          }
        )
      });
    }
  }, [date])

  function setTodaySell(e){
    e.preventDefault();
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    setDate(dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"))
  }

  function setTodaySellChange(e){
    e.preventDefault();
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
    setDateToChange(dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"))
  }

  const [correctExpense, setCorrect] = React.useState(false);
  const [expenseError, setError] = React.useState(false);
  const [correctDelete, setCorrectDelete] = React.useState(false);
  const [deleteError, setErrorDelete] = React.useState(false);
  const [correctChange, setCorrectChange] = React.useState(false);
  const [errorChange, setErrorChange] = React.useState(false);
  const [correctSubscription, setCorrectSubscription] = React.useState(false);
  const [errorSubscription, setErrorSubscription] = React.useState(false);

  const [newExpense, {loadingExpense, errorExpense, data: dataExpense}] = useMutation(CREATE_EXPENSE, {
    onError: (e) => {
      setError(true);
      setTimeout( () => {
        setError(false);
      }, 1500);
    },
    onCompleted: () => {
      setCorrect(true);
      handleSetAllDataUpdated();
      setTimeout( () => {
        setCorrect(false);
      }, 1500);
    }
  });

  const [deleteExp, {loadingDelete, errorDelete, data: dataDelete}] = useMutation(DELETE_EXPENSE, {
    onError: (e) => {
      setErrorDelete(true);
      setTimeout( () => {
        setErrorDelete(false);
      }, 1500);
    },
    onCompleted: () => {
      setCorrectDelete(true);
      handleSetAllDataUpdated();
      removeDeletedItem();
      setTimeout( () => {
        setCorrectDelete(false);
      }, 1500);
    }
  });

  const [deleteSub, {loadingSubscription, errorSub, data: dataSubscription}] = useMutation(DELETE_SUBSCRIPTION, {
    onError: (e) => {
      setErrorSubscription(true);
      setTimeout( () => {
        setErrorSubscription(false);
      }, 1500);
    },
    onCompleted: () => {
      setCorrectSubscription(true);
      removeDeletedItem();
      setTimeout( () => {
        setCorrectSubscription(false);
      }, 1500);
    }
  });

  const [changeExp, {loadingChange, changeError, data: dataChange}] = useMutation(CHANGE_EXPENSE, {
    onError: (e) => {
      setErrorChange(true);
      setTimeout( () => {
        setErrorChange(false);
      }, 1500);
    },
    onCompleted: () => {
      setCorrectChange(true);
      removeDeletedItem();
      refetchExpenses()
      .then((result) => {
        manualUpdate(result.data);
      })
      setTimeout( () => {
        setCorrectChange(false);
      }, 1500);
    }
  });

  function createExpense(e){
    e.preventDefault();
    let expensePeriod = null;
    if (typeSelections.periodica){
      expensePeriod = i18n.language == 'it' ? timeSelections.toLowerCase() : getPeriodFormat(timeSelections);
    }
    try{
      newExpense({
        variables: {
          expenseArgs: {
            title: expenseInfo.title,
            amount: parseInt(expenseInfo.amount),
            category: expenseInfo.category,
            date: expenseInfo.expenseDate,
            type: typeSelections.singola ? "singola" : "periodica",
            period: expensePeriod
          }
        }
      })
    } catch(error){
      console.log(error);
    }
    closeForm();
  }

  React.useEffect( () => {
    if(dataExpense){
      const {createExpense} = dataExpense;
      setExpenseValues({
        weekTotal: createExpense.weekTotal,
        monthTotal: createExpense.monthTotal,
        yearTotal: createExpense.yearTotal,
        total: createExpense.total
      });
      setTotal(createExpense.weekTotal);
      setActiveExpenses(createExpense.weeklyExpenses)
      setWeekExpenses(createExpense.weeklyExpenses);
      setMonthExpenses(createExpense.monthlyExpenses);
      setYearExpenses(createExpense.yearlyExpenses);
      setTotalExpenses(createExpense.expenses)
    }
  }, [dataExpense])

  const [filterTypeSelections, setFilterType] = React.useState({
    singole: true,
    periodiche: true
  })

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [categories, setCategories] = React.useState("");
  const [nameSearch, setNameSearch] = React.useState("");
  const [showFilters, setShowFilters] = React.useState(false); 
  const [closing, setClosing] = React.useState(false);
  const [filtersUsed, setFiltersUsed] = React.useState(false);


  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const [filters, setFilters] = React.useState({
    startDate: null,
    endDate: `${year}-${month}-${day}`,
  })


  React.useEffect( () => {
    if (startDate){
      const formattedDate = formatDateCorrectly(startDate, i18n.language);
      setFilters( prevFilters => {
        return({
          ...prevFilters,
          startDate: formattedDate
        })
      })
    }
  }, [startDate])


  React.useEffect( () => {
    if (endDate){
      const formattedDate = formatDateCorrectly(endDate, i18n.language);
      setFilters( prevFilters => {
        return({
          ...prevFilters,
          endDate: formattedDate
        })
      })
    }
  }, [endDate])

  function changeFilterType(e){
    const {id} = e.target;
    setFilterType(prevType => {
      return({
        ...prevType,
        [id]: !prevType[id] 
      })
    })
  }

  function handleCategories(event){
    const {value} = event.target;

    setCategories(value);
  }

  function handleNameSearch(event){
    const {value} = event.target;

    setNameSearch(value);
  }

  function changeShowFilters(){
    if (!showFilters){
      setShowFilters(!showFilters);
    }
    else{
      setClosing(true);      
      setTimeout( () => {
        setShowFilters(!showFilters);
        setClosing(false);
      }, 300)
    }
  }

  function manualUpdate(data){
    const {getExpensesHome} = data;
      setExpenseValues({
        weekTotal: getExpensesHome.weekTotal,
        monthTotal: getExpensesHome.monthTotal,
        yearTotal: getExpensesHome.yearTotal,
        total: getExpensesHome.total
      });
      setTotal(getExpensesHome.weekTotal);
      setActiveExpenses(getExpensesHome.weeklyExpenses)
      setActivePeriod("week");
      setWeekExpenses(getExpensesHome.weeklyExpenses);
      setMonthExpenses(getExpensesHome.monthlyExpenses);
      setYearExpenses(getExpensesHome.yearlyExpenses);
      setTotalExpenses(getExpensesHome.expenses)
  }

  function cleanFilters(closeBool){
    refetchExpenses()
      .then((result) => {
        manualUpdate(result.data);
      })
    setCategories("");
    setNameSearch("");
    setFilterType({
      singole: true,
      periodiche: true
    })
    setStartDate(null);
    setEndDate(null);
    if (closeBool) closeFilters();
  }

  function closeFilters(){
    setClosing(true);      
    setTimeout( () => {
      setShowFilters(!showFilters);
      setClosing(false);
    }, 300)
  }
  
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smooth scrolling, use 'auto' for instant scrolling
    });
  }

  function useFilters(){
    setClosing(true);      
    setTimeout( () => {
      setShowFilters(!showFilters);
      setClosing(false);
    }, 300)

    var expenseTypes = [];

    if (filterTypeSelections.singole){
      expenseTypes.push("singola");
    }
    if (filterTypeSelections.periodiche){
      expenseTypes.push("periodica");
    }

    const varFilters = {
      ...filters,
      expenseTypes: [...expenseTypes],
      categories: categories ? [...stringToArray(categories)] : null,
      name: nameSearch || null,
    }

    getExpenseFilter({
      variables:{
        filters: {...varFilters}
      }
    });
    setFiltersUsed(true);
    scrollTop();
  }
  
  const [showDelete, setShowDelete] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [showSingleChange, setShowSingle] = React.useState(false);
  const [showSubChange, setShowSub] = React.useState(false);
  const [showUnsubscribe, setShowUnsubscribe] = React.useState(false);
  const [expenseToChange, setExpenseToChange] = React.useState();
  const [elementDeleted, setElementDeleted] = React.useState(false);

  function showOptionWindow(expense){
    setExpenseToChange(expense);
    setShowOptions(true);
  }

  function closeOptionsWindow(){
    setShowOptions(false)
  }  

  function closeDeleteWindow(){
    setShowDelete(false)
  }

  function closeUnsubscribeWindow(){
    setShowUnsubscribe(false)
  }

  function showDeleteWindow(){
    setShowOptions(false);
    setShowDelete(true);
  }

  function changeExpense(){
    setShowOptions(false);
    if(expenseToChange.type === "singola"){
      setShowSingle(true);
    } else{
        setShowSub(true);
        if (i18n.language == 'en'){
          setTimeSelectionsChange(getExpensePeriodEnglish(expenseToChange.period));
        } else{
          setTimeSelectionsChange(expenseToChange.period);
        }
    }
    setDateToChange();
  }

  function initUnsubscribe(){
    setShowOptions(false);
    setShowUnsubscribe(true);
  }


  function deleteExpense(){
    setShowDelete(false);
    deleteExp({
      variables: {
        expenseId: expenseToChange.id
      }
    })
  }


  React.useEffect(() => {
    if(elementDeleted){
      assignActiveExpenses();
      setElementDeleted(false)
    }
  }, [elementDeleted])

  const removeDeletedItem = () => {
    //week array
    var foundIndex = weekExpenses.findIndex(expense => expense.id === expenseToChange.id);
    if(foundIndex !== -1){
      setExpenseValues(prevExp => {
        return({
          ...prevExp,
          weekTotal: expenseValues.weekTotal - expenseToChange.amount
        })
      })
      setWeekExpenses((prevExpenses) =>
        prevExpenses.filter((expense) => expense.id !== expenseToChange.id)
      );
    }

    //month
    foundIndex = monthExpenses.findIndex(expense => expense.id === expenseToChange.id);
    if(foundIndex !== -1){
      setExpenseValues(prevExp => {
        return({
          ...prevExp,
          monthTotal: expenseValues.monthTotal - expenseToChange.amount
        })
      })
      setMonthExpenses(prevExpenses => {
        const newExpenses = prevExpenses.filter(expense => expense.id !== expenseToChange.id);
        return newExpenses
      })
    }

    foundIndex = yearExpenses.findIndex(expense => expense.id === expenseToChange.id);
    if(foundIndex !== -1){
      setExpenseValues(prevExp => {
        return({
          ...prevExp,
          yearTotal: expenseValues.yearTotal - expenseToChange.amount
        })
      })
      setYearExpenses(prevExpenses => {
        const newExpenses = prevExpenses.filter(expense => expense.id !== expenseToChange.id);
        return newExpenses
      })
    }

    foundIndex = totalExpenses.findIndex(expense => expense.id === expenseToChange.id);
    if(foundIndex !== -1){
      setExpenseValues(prevExp => {
        return({
          ...prevExp,
          total: expenseValues.total - expenseToChange.amount
        })
      })
      setTotalExpenses(prevExpenses => {
        const newExpenses = prevExpenses.filter(expense => expense.id !== expenseToChange.id);
        return newExpenses
      })
    }
    setExpenseToChange(null);
    setElementDeleted(true);
  }


  const newExpenseRef = React.useRef();
  const changeExpenseRef = React.useRef();

  const singleExpenseRef = React.useRef();
  const periodicExpenseRef = React.useRef();

  const closeSingleChange = () => { setShowSingle(false)}
  const closeSubChange = () => { setShowSub(false)}

  const [dateChange, setDateChange] = React.useState();

  const setDateToChange = () => {
    setDateChange(dayjs(expenseToChange.date, "YYYY-MM-DD"))
  }

  function modifySingleExpense(){
    changeExp({
      variables:{
        expenseId: expenseToChange.id,
        expenseInfo: {
          amount: parseFloat(expenseToChange.amount),
          title: expenseToChange.title,
          period: null,
          category: expenseToChange.category,
          date: expenseToChange.date,
          isSingle: true
        }
      }
    });
    closeSingleChange();
  }

  function modifyPeriodExpense(){
    changeExp({
      variables:{
        expenseId: expenseToChange.id,
        expenseInfo: {
          amount: parseFloat(expenseToChange.amount),
          title: expenseToChange.title,
          period: i18n.language == 'it' ? timeChangeSelections.toLowerCase() : getPeriodFormat(timeChangeSelections),
          category: expenseToChange.category,
          date: expenseToChange.date,
          isSingle: false
        }
      }
    });
    closeSubChange();
  }

  React.useEffect( () => {
    if (dateChange){
      const formattedDate = formatDateCorrectly(dateChange, i18n.language);
      setExpenseToChange( prevExp => {
        return(
          {
            ...prevExp,
            date: formattedDate
          }
        )
      });
    }
  }, [dateChange])

  function unsubscribe(){
    setShowUnsubscribe(false);
    deleteSub({
      variables: {
        expenseName: expenseToChange.title
      }
    });
  }

  return(
    <div>
    <HeaderFixed 
      pageTitle={t('expensePage.title')} 
      menuPage="EXPENSE"
    />
    {!loadingExpenses ? (
      <div>
        <PeriodContainer>
          <ButtonsContainer>
            <PeriodButton 
              selected={activePeriod === "week"} 
              onClick={() => setActivePeriod("week")}
            >
              {t('expensePage.week')}
            </PeriodButton>
            <PeriodButton 
              selected={activePeriod === "month"} 
              onClick={() => setActivePeriod("month")}
            >
              {t('expensePage.month')}
            </PeriodButton>
            <PeriodButton 
              selected={activePeriod === "year"} 
              onClick={() => setActivePeriod("year")}
            >
              {t('expensePage.year')}
            </PeriodButton>
            <PeriodButton 
              selected={activePeriod === "all"} 
              onClick={() => setActivePeriod("all")}
            >
              {t('expensePage.all')}
            </PeriodButton>
          </ButtonsContainer>
          <PeriodDate>
            {activePeriod === "all" ? allDate(totalExpenses) : italianDate(activePeriod, i18n.language)}
          </PeriodDate>
        </PeriodContainer>
        
        <ExpensePageChart 
          data={dataChart} 
          total={total} 
          openNewForm={() => setShow(true)} 
        />
        
        <ContainerSearch>
          <DivContainer>
            <SearchContainer>
              <SearchInputContainer>
                <label htmlFor="searchInput">
                  <img src={search_icon} />
                </label>
                <SearchInput
                  type="text"
                  id="searchInput"
                  placeholder={t('expensePage.searchPlaceholder')}
                  value={searchName}
                  onChange={handleSearch}
                />
              </SearchInputContainer>
            </SearchContainer>
            <FilterIcon 
              src={filter_icon} 
              onClick={changeShowFilters} 
            />
          </DivContainer>
        </ContainerSearch>
        
        {showFilters && (
          <Filters visible={showFilters} closing={closing}>
            <FilterTitle>
              {t('expensePage.filterTitleName')}
            </FilterTitle>
            <CenterContainer>
              <FormField 
                width={longInput} 
                name={t('expensePage.expenseDescriptionField')}  
                inputName="nameSearch" 
                value={nameSearch} 
                handler={handleNameSearch} 
              />
            </CenterContainer>
            
            <FilterTitle>
              {t('expensePage.filterTitleCategories')}
            </FilterTitle>
            <FilterSubTitle>
              {t('expensePage.filterSubtitleCategories')}
            </FilterSubTitle>
            <CenterContainer>
              <FormField 
                width={longInput} 
                name={t('expensePage.categoryField')}  
                inputName="categories" 
                value={categories} 
                handler={handleCategories} 
              />
            </CenterContainer>
            
            <FilterTitle>
              {t('expensePage.filterTitleDate')}
            </FilterTitle>
            <FormRow>
              <CDatePicker date={startDate} setDate={setStartDate} width={"48%"} />
              <CDatePicker date={endDate} setDate={setEndDate} width={"48%"} />
            </FormRow>
            
            <TypeFilters>
              <FilterTitle>
                {t('expensePage.filterTitleType')}
              </FilterTitle>
              <TypesContainer>
                <TypesFiltering 
                  type={t('expensePage.singleExpense')} 
                  state={filterTypeSelections.singole} 
                  onClickFunction={changeFilterType} 
                  id="singole"
                />
                <TypesFiltering 
                  type={t('expensePage.recurringExpense')} 
                  state={filterTypeSelections.periodiche} 
                  onClickFunction={changeFilterType} 
                  id="periodiche"
                />
              </TypesContainer>
            </TypeFilters>
            
            <div className="flex justify-around px-2 my-2">
              <MainButton 
                active={true} 
                text={t('expensePage.applyFiltersButton')} 
                fontSize="12px" 
                onClickFunction={useFilters}
              />
              <MainButton 
                active={true} 
                text={t('expensePage.clearFiltersButton')} 
                fontSize="12px" 
                onClickFunction={() => { cleanFilters(true) }}
              />
            </div>
          </Filters>
        )}
        
        {activeExpenses.length > 0 && (
          <ExpensesShower 
            expenses={expensesToShow} 
            showOptions={showOptionWindow}
          />
        )}
      </div>
    ) : (
      <LoadingAnimation />
    )}
    
    <Navfixed />


      <Transition.Root show={showForm} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 dialogExpense overflow-y-auto" 
          open={showForm}
          onClose={() => closeForm()}
          initialFocus={newExpenseRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <FormContainer>
                <FormTitle ref={newExpenseRef}>
                  {t('expensePage.newExpenseTitle')}
                </FormTitle>
                
                <FormField 
                  width={"260px"} 
                  name={t('expensePage.expenseDescriptionField')} 
                  inputName="title" 
                  value={expenseInfo.title} 
                  handler={handleInput} 
                  fontDim="14px" 
                  type="text" 
                  readonlyF={true} 
                  tabIndex="-1"
                />
                
                <FormField 
                  width={"260px"} 
                  name={t('expensePage.categoryField')} 
                  inputName="category" 
                  value={expenseInfo.category} 
                  handler={handleInput} 
                  fontDim="14px" 
                  type="text" 
                  readonlyF={true} 
                  tabIndex="-1"
                />
                
                <FormField 
                  width={"260px"} 
                  name={t('expensePage.amountField')} 
                  pHolder="€" 
                  inputName="amount" 
                  value={expenseInfo.amount} 
                  handler={handleInput} 
                  fontDim="14px" 
                  type="number"
                />
                
                <TypeFilters>
                  <FilterTitle>
                    {t('expensePage.expenseTypeLabel')}
                  </FilterTitle>
                  <TypesContainer>
                    <TypesFiltering 
                      type={t('expensePage.singleExpense')} 
                      state={typeSelections.singola} 
                      onClickFunction={changeType} 
                      id="singola"
                    />
                    <TypesFiltering 
                      type={t('expensePage.recurringExpense')} 
                      state={typeSelections.periodica} 
                      onClickFunction={changeType} 
                      id="periodica"
                    />
                  </TypesContainer>
                </TypeFilters>
                
                {typeSelections.periodica && (
                  <TypeFilters>
                    <FilterTitle>
                      {t('expensePage.periodLabel')}
                    </FilterTitle>
                    <CSelector 
                      width={"200px"} 
                      options={timeOptions} 
                      val={timeSelections} 
                      func={setTimeSelections} 
                    />
                  </TypeFilters>
                )}
                
                <SpaceDiv>
                  <FormRow>
                    <CDatePicker title={t('expensePage.periodLabel')} date={date} setDate={setDate}/>
                    <StyledButton onClick={setTodaySell}>
                      {t('expensePage.todayButton')}
                    </StyledButton>
                  </FormRow>
                </SpaceDiv>
                
                <div className="flex w-full justify-center">
                  <MainButton 
                    active={true} 
                    text={t('expensePage.registerExpenseButton')} 
                    fontSize="16px" 
                    onClickFunction={(e) => createExpense(e)}
                  />
                </div>
              </FormContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root> 

      <Transition.Root show={showDelete} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 dialogExpense overflow-y-auto" 
          open={showDelete}
          onClose={() => closeDeleteWindow()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ChoiceContainer>
                <div className="textDiv">
                  {t('expensePage.confirmDeleteExpense')}
                </div>
                <div className="buttonDiv">
                  <button 
                    className="choiceButton left" 
                    onClick={() => deleteExpense()}
                  >
                    {t('common.yes')}
                  </button>
                  <button 
                    className="choiceButton right" 
                    onClick={() => closeDeleteWindow()}
                  >
                    {t('common.no')}
                  </button>
                </div>
              </ChoiceContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={showUnsubscribe} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 dialogExpense overflow-y-auto" 
          open={showUnsubscribe}
          onClose={() => closeUnsubscribeWindow()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {expenseToChange ? 
              <ChoiceContainer>
                <div className="textDiv">
                  {t('expensePage.confirmUnsubscribe', { title: expenseToChange.title })}
                </div>
                <div className="buttonDiv">
                  <button className="choiceButton left" onClick={() => unsubscribe()}>
                    {t('common.yes')}
                  </button>
                  <button className="choiceButton right" onClick={() => closeUnsubscribeWindow()}>
                    {t('common.no')}
                  </button>
                </div>
              </ChoiceContainer> : <div></div>}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>


      <Transition.Root show={showOptions} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 dialogExpense overflow-y-auto" 
          open={showOptions}
          onClose={() => closeOptionsWindow()}
          initialFocus={changeExpenseRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <OptionContainer ref={changeExpenseRef}>
                {expenseToChange && 
                  <InfoContainer>
                    <InfoRow>{t('expensePage.expenseInfoDescription')}: <span className="info">{expenseToChange.title}</span></InfoRow>
                    <InfoRow>{t('expensePage.expenseInfoAmount')}: <span className="info">{expenseToChange.amount}€</span></InfoRow>
                    <InfoRow>{t('expensePage.expenseInfoDate')}: <span className="info">{formatDate(expenseToChange.date)}</span></InfoRow>
                    {expenseToChange.period &&
                      <InfoRow>{t('expensePage.expenseInfoNextPayment')}: <span className="info">{formatDate(calculateExpiryDate(expenseToChange.period, expenseToChange.date))}</span></InfoRow>
                    }
                  </InfoContainer>
                }
                <OptionsContainer>
                  {expenseToChange && expenseToChange.period && 
                    <OptionWare onClick={() => initUnsubscribe()}>
                      <OptionWareIcon src={unsubscribe_icon}/>
                      <OptionText className="sell">{t('expensePage.unsubscribeOption')}</OptionText>
                    </OptionWare>
                  }
                  <OptionWare onClick={() => changeExpense()}>
                    <OptionWareIcon src={edit_icon}/>
                    <OptionText>{t('expensePage.editExpenseOption')}</OptionText>
                  </OptionWare>
                  <OptionWare onClick={() => showDeleteWindow()}>
                    <OptionWareIcon src={delete_red}/>
                    <OptionText className="delete">{t('expensePage.deleteExpenseOption')}</OptionText>
                  </OptionWare>
                </OptionsContainer>
              </OptionContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root> 

      {showSingleChange && <Transition.Root show={showSingleChange} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 dialogExpense overflow-y-auto" 
          open={showSingleChange}
          onClose={() => closeSingleChange()}
          initialFocus={singleExpenseRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <FormContainer>
                <FormTitle ref={singleExpenseRef}>{t('expensePage.editExpenseOption')}</FormTitle>
                <FormField width={"260px"} name={t('expensePage.expenseDescriptionField')} inputName="title" value={expenseToChange.title} handler={handleChange} fontDim="14px" type="text" readonlyF={true} tabIndex="-1"/>
                <FormField width={"260px"} name={t('expensePage.categoryField')} inputName="category" value={expenseToChange.category} handler={handleChange} fontDim="14px" type="text" readonlyF={true} tabIndex="-1"/>
                <FormField width={"260px"} name={t('expensePage.amountField')} pHolder="€" inputName="amount" value={expenseToChange.amount} handler={handleChange} fontDim="14px" type="number"/>
                <SpaceDiv>
                  <FormRow>
                    <CDatePicker title={t('expensePage.periodLabel')} date={dateChange} setDate={setDateChange}/>
                    <StyledButton onClick={setTodaySellChange}>{t('expensePage.todayButton')}</StyledButton>
                  </FormRow>
                </SpaceDiv>
                <div className="flex w-full justify-center">
                  <MainButton active={true} text={t('expensePage.editExpenseOption')} fontSize="16px" onClickFunction={() => modifySingleExpense()}/>
                </div>
              </FormContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>} 

      {showSubChange && <Transition.Root show={showSubChange} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 dialogExpense overflow-y-auto" 
          open={showSubChange}
          onClose={() => closeSubChange()}
          initialFocus={periodicExpenseRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <FormContainer>
                <FormTitle ref={singleExpenseRef}>{t('expensePage.editExpenseOption')}</FormTitle>
                <FormField width={"260px"} name={t('expensePage.expenseDescriptionField')} inputName="title" value={expenseToChange.title} handler={handleChange} fontDim="14px" type="text" readonlyF={true} tabIndex="-1"/>
                <FormField width={"260px"} name={t('expensePage.categoryField')} inputName="category" value={expenseToChange.category} handler={handleChange} fontDim="14px" type="text" readonlyF={true} tabIndex="-1"/>
                <FormField width={"260px"} name={t('expensePage.amountField')} pHolder="€" inputName="amount" value={expenseToChange.amount} handler={handleChange} fontDim="14px" type="number"/>
                <TypeFilters>
                  <FilterTitle>
                    {t('expensePage.periodLabel')}
                  </FilterTitle>
                  <CSelector width={"200px"} options={timeOptions} val={timeChangeSelections} func={setTimeSelectionsChange} />
                </TypeFilters>
                <SpaceDiv>
                  <FormRow>
                    <CDatePicker title={t('expensePage.periodLabel')} date={dateChange} setDate={setDateChange}/>
                    <StyledButton onClick={setTodaySellChange}>{t('expensePage.todayButton')}</StyledButton>
                  </FormRow>
                </SpaceDiv>
                <div className="flex w-full justify-center">
                  <MainButton active={true} text={t('expensePage.editExpenseOption')} fontSize="16px" onClickFunction={() => modifyPeriodExpense()}/>
                </div>
              </FormContainer>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>}

      {correctDelete && <CorrectPopUp text={t('expensePage.deleteSuccessMessage')} />}
      {correctExpense && <CorrectPopUp text={t('expensePage.addSuccessMessage')} />}
      {deleteError && <ErrorPopUp text={t('expensePage.deleteErrorMessage')} />}
      {expenseError && <ErrorPopUp text={t('expensePage.addErrorMessage')} />}
      {correctChange && <CorrectPopUp text={t('expensePage.changeSuccessMessage')} />}
      {errorChange && <ErrorPopUp text={t('expensePage.changeErrorMessage')} />}
      {correctSubscription && <CorrectPopUp text={t('expensePage.subscriptionCancelSuccessMessage')} />}
      {errorSubscription && <ErrorPopUp text={t('expensePage.subscriptionCancelErrorMessage')} />}
    </div>
  )
}