import React from "react";
import styled from "styled-components"
import tw from "twin.macro"
import { formatDate } from "../const";
import i18n from "../i18n";

const ExpensesContainer = styled.div`
  ${tw`
      mt-2
      flex
      flex-col
      items-center
      w-full
      text-white
  `}
`

const Expense = styled.div`
  ${tw`
      flex
      items-center
      justify-between
      py-2
      px-6
      my-2
  `}

  border-radius: 15px;
  box-shadow: 0px 0px 4px 2px black;
  width: 90%;
  background-color: #050505aa;
`

const TextContainer = styled.div`
  ${tw`
      flex
      flex-col
      items-start
  `}

  width: 200px;

  .title{
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .date{
    opacity: 0.9;
    font-size: 14px;
  }

  .category{
    color: #00dadacc;
    border: 1px solid #00dadacc;
    padding: 0px 5px;
    margin-left: 8px;
    border-radius: 10px;
    font-size: 14px;
  }

  .dateCatContainer{
    display: flex;
    align-items: center;
  }
`

const PriceContainer = styled.div`
  font-size: 20px;
`



export default function ExpensesShower(props){
  const { expenses, showOptions } = props;

  const [language, setLanguage] = React.useState('it-IT');

  React.useEffect(() => {
    if (i18n.language == 'en'){
      setLanguage('en-US');
    } 
  }, []);

  return(
    <ExpensesContainer>
      {expenses.map( (expense, index) => (
        <Expense
          key={index}
          onClick={() => showOptions(expense)}
        >
          <TextContainer>
            <span className="title">{expense.title}</span>
            <div className="dateCatContainer">
              <span className="date">{formatDate(expense.date, language)}</span>
              <span className="category">{expense.category}</span>
            </div>
          </TextContainer>
          <PriceContainer>
            - {expense.amount}€
          </PriceContainer>
        </Expense>
      ))}
    </ExpensesContainer>
  )
}