import styled from "styled-components"
import tw from "twin.macro"
import Navfixed from "../components/Navfixed"
import { useNavigate } from "react-router-dom"
import HeaderFixed from "../components/HeaderFixed"
import MainButton from "../components/MainButton"
import roundAlert from "../icons/roundAlert.png";
import { useTranslation } from 'react-i18next';

const NoItems = styled.div`
  @keyframes appear{
    0%{
      transform: TranslateY(20px);
      opacity: 0;
    }
    100%{
      transform: TranslateY(0px);
      opacity: 1;
    }
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-10px); }
    40% { transform: translateX(10px); }
    60% { transform: translateX(-10px); }
    80% { transform: translateX(10px); }
    100% { transform: translateX(0); }
  }

  ${tw`
      flex
      flex-col
      text-white
      font-semibold
      justify-center
      items-center
  `}

  margin-top: 30px;

  animation: appear 0.5s ease-in-out;

  span {
    font-size: 14px;
  }

  img{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    animation: shake 0.5s ease-in-out 0.5s;
  }
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 90%;
  background: black;
  padding: 18px 10px;
  border-radius: 15px;
  text-align: center
`

const PageContainer= styled.div`
  ${tw`
      flex
      flex-col
      items-center
      justify-center
  `}
  margin-top: 20px;
`

export default function PaymentPage(){
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <HeaderFixed pageTitle={t('SubscriptionPage.pageTitle')} />
      <PageContainer>
        <NoItems>
          <img src={roundAlert} />
          <MessageContainer>
            <span>{t('SubscriptionPage.noItemsMessage')}</span>
          </MessageContainer>
          <MainButton active={true} text={t('SubscriptionPage.goToHomeButtonText')} onClickFunction={() => {navigate('/home');}} />
        </NoItems>
      </PageContainer>
      <Navfixed currentPage={"payment"} />
    </div>
  )
}