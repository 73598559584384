import OtpInput from 'react-otp-input';
import styled from "styled-components"
import tw from 'twin.macro';
import './OTPInput.css'
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  ${tw`
      flex
      flex-col
      justify-center
      items-center  
      mb-6
  `}
`
const OtpText = styled.span`
  ${tw`
      text-white  
      px-10
  `}
  width: 100%;
  font-size: 35px;
  font-weight: 600;
  .colored{
    color: #00dada;
  }
`

const TextContainer = styled.div`
  ${tw`
      mt-3
      mb-8
      font-medium
      pl-10
      pr-6
  `}
  color: rgba(255,255,255,0.8);
  font-size: 14px;
`

export default function OTPInput(props) {
  const { otp, setOtp } = props;
  const { t } = useTranslation();

  return (
    <Container>
    <OtpText>
      {t('OtpInput.verify')}
    </OtpText>
    <TextContainer>
      {t('OtpInput.otpMessage')}
    </TextContainer>
    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span className="coloredOTP">-</span>}
      renderInput={(props) => <input {...props} />}
      inputStyle={"inputOTP"}
      containerStyle={"containerOTP"}
      shouldAutoFocus={true}
      skipDefaultStyles={true}
    />
    </Container>
  );
}