import LoginPage from './main_pages/LoginPage';
import {Route, Routes} from 'react-router-dom';
import RegistrationPage from './main_pages/RegistrationPage';
import WalletPage from './main_pages/WalletPage';
import NewItemPage from './main_pages/NewItemPage';
import HomePage from './main_pages/HomePage';
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/zh-cn.js'
import ProfilePage from './main_pages/ProfilePage';
import RecoveryPage from './main_pages/RecoveryPage';
import ChangeInfoPage from './main_pages/ChangeInfoPage';
import ChangePass from './main_pages/ChangePass';
import ObjectivesPage from './main_pages/ObjectivesPage';
import WarehousePage from './main_pages/WarehousePage';
import DataPage from './main_pages/DataPage';
import TaxesPage from './main_pages/TaxesPage';
import GuidePage from './main_pages/GuidePage';
import DocumentsPage from './main_pages/DocumentsPage';
import PaymentPage from './main_pages/PaymentPage';
import VerificationPage from './main_pages/VerificationPage';
import ExpensesPage from './main_pages/ExpensesPage';
import ProtectedRoute from './components/ProtectedRoute';
import './i18n';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh-cn.js">
      <Routes>
        <Route 
          path="/"
          element={
            <LoginPage />
          }
        />
        <Route 
          path="/Login"
          element={
            <div>
              <LoginPage />
            </div>
          }
        />
        <Route
          path="/registrati"
          element={
            <RegistrationPage />
          }
        />
        <Route 
          path="/RecuperoPassword"
          element={
            <div>
              <RecoveryPage />
            </div>
          }
        />
        <Route 
          path="/Verifica"
          element={
            <div>
              <VerificationPage />
            </div>
          }
        />

        <Route 
            path="/Home"
            element={
              <ProtectedRoute>
                <HomePage/>
              </ProtectedRoute>
          }
          />
        <Route
          path="/Goals"
          element={
            <ProtectedRoute>
                <ObjectivesPage/>
            </ProtectedRoute>
          }
        />
        <Route 
          path="/contabilità"
          element={
            <ProtectedRoute>
                <WalletPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/magazzino"
          element={
            <ProtectedRoute>
              <WarehousePage />
            </ProtectedRoute>
          }
        />
        <Route
            path="/spese"
            element={
                <ProtectedRoute>
                    <ExpensesPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/profilo"
            element={
                <ProtectedRoute>
                    <ProfilePage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/profilo/modificaDati"
            element={
                <ProtectedRoute>
                    <ChangeInfoPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/profilo/cambiaPassword"
            element={
                <ProtectedRoute>
                    <ChangePass />
                </ProtectedRoute>
            }
        />
        <Route
            path="/profilo/gestionePiano"
            element={
                <ProtectedRoute>
                    <PaymentPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/nuovoOggetto/"
            element={
                <ProtectedRoute>
                    <NewItemPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/data"
            element={
                <ProtectedRoute>
                    <DataPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/tasse"
            element={
                <ProtectedRoute>
                    <TaxesPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/documenti"
            element={
                <ProtectedRoute>
                    <DocumentsPage />
                </ProtectedRoute>
            }
        />
        <Route
            path="/guida"
            element={
                <ProtectedRoute>
                    <GuidePage />
                </ProtectedRoute>
            }
        />
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
